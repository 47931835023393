import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatIconRegistry} from "@angular/material/icon";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from '../../../../../environments/environment';
import {LocationOffering, TradingHours} from '../../../../core/model/location-offering.interface';
import {GenericTypeDefinition} from "../../../../core/model/retailer.model";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";


@Component({
  selector: 'app-offering-listing',
  templateUrl: './offering-listing.component.html',
  styleUrls: ['./offering-listing.component.scss']
})
export class OfferingListingComponent implements OnInit {
  @Input() offering!: LocationOffering;
  @Input() isPublic!: boolean;
  @Output() toggleDrawer = new EventEmitter<boolean>();
  @Output() apply = new EventEmitter<void>();
  @Output() enter = new EventEmitter<void>();

  public zoom: number = 0;
  public latitude: number = 0;
  public longitude: number = 0;
  googleMapsLoaded = false;

  addressForm: FormGroup;
  private listOfDays: TradingHours[] = [
    {
      name: "Monday"
    },
    {
      name: "Tuesday"
    },
    {
      name: "Wednesday"
    },
    {
      name: "Thursday"
    },
    {
      name: "Friday"
    },
    {
      name: "Saturday"
    },
    {
      name: "Sunday"
    },
    {
      name: "Public Holidays"
    }
  ];

  shortenCategories: boolean = true;
  showMoreLabel: string = 'Show more';

  constructor(private cd: ChangeDetectorRef,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private _snackBar: MatSnackBar,
              private context: CurrentContextService) {
    this.matIconRegistry.addSvgIcon('back-arrow-button', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/icons/back-arrow-button.svg'));
    this.matIconRegistry.addSvgIcon('location-away', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/icons/location_away.svg'));


    this.addressForm = new FormGroup({
      address: new FormControl('')
    });

    if (this.offering && this.offering.offeringAddress) {
      this.addressForm.get('address')!.setValue(this.offering.offeringAddress);
    }
    this.addressForm.disable();
  }

  ngOnInit(): void {
    this.zoom = 10;
    this.cd.detectChanges();
  }

  onDrawerOpen(loaded: boolean): void {
    // Call this method when the drawer is opened
    this.googleMapsLoaded = loaded;
    this.geocodeAddress(this.offering.offeringAddress!)
      .then(() => {
        this.setCurrentPosition();
      })
  }

  // Method to perform geocoding using Google Maps Geocoder
  geocodeAddress(address: string): Promise<google.maps.GeocoderResult> {
    return new Promise((resolve, reject) => {
      if (!this.googleMapsLoaded) {
        reject('Google Maps API not loaded');
        return;
      }
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({address: address}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.latitude = results[0].geometry.location.lat();
          this.longitude = results[0].geometry.location.lng();
          resolve(results[0]);
        } else {
          reject(`Geocode was not successful for the following reason: ${status}`);
        }
      });
    });
  }

  getTradingHours(): TradingHours[] | null {
    return this.listOfDays;
  }

  displayTimeRange(day: TradingHours): string {

    if (this.offering && this.offering.offeringListing!.tradingHours) {
      let index: number = this.offering.offeringListing!.tradingHours.findIndex((trading: TradingHours) => trading.name.toLowerCase() === day.name.toLowerCase());
      if (index >= 0) {
        let chosenDay = this.offering.offeringListing!.tradingHours.at(index)!;
        if (chosenDay.startTime && chosenDay.endTime) {
          return `${chosenDay.startTime} - ${chosenDay.endTime}`;
        }
      }
    }
    return 'Closed';
  }

  getUrlToListing(): string {
    return `${environment.apiPortal}/${this.context.getCurrentLocationCode()}/${this.offering.offeringCode}/home`;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {duration: 1000, verticalPosition: 'top'});
  }

  setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.zoom = 12;
      });
    }
  }

  displayOfferingCategories(): GenericTypeDefinition[] {
    if (this.shortenCategories) {
      if (this.offering.offeringListing!.offeringListingIndustries.length > this.getTradingHours()!.length) {
        this.showMoreLabel = 'Show more';
        return this.offering.offeringListing!.offeringListingIndustries.slice(0, this.getTradingHours()!.length - 1);
      }
      this.showMoreLabel = '';
      return this.offering.offeringListing!.offeringListingIndustries;
    }
    this.showMoreLabel = 'Show less';
    return this.offering.offeringListing!.offeringListingIndustries;
  }

  openMapInNewTab() {
    const url = `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`;
    window.open(url, '_blank');
  }
}
