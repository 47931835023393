import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, finalize, Observable, of} from 'rxjs';
import {GenericService, PageRequest} from "../services/generic.service";
import {catchError} from "rxjs/operators";

export class GenericDatasource<T = any> implements DataSource<any>{

  protected listSubject = new BehaviorSubject<any[]>([]);
  protected loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  public totalPages = 0;
  public totalElements = 0;

  constructor(private service: any & GenericService) {
  }

  public connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.listSubject.asObservable();
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    this.listSubject.complete();
    this.loadingSubject.complete();
  }

  public loadData(suffix: string, page: PageRequest, filters?: { name: string, val: any }[]): void {
    this.loadingSubject.next(true);

    this.service.getAll(suffix, page, filters)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((value: any) => {
        this.totalPages = value.totalPages;
        this.totalElements = value.totalElements;
        this.listSubject.next(value.content);
      });
  };

  public loadDataUnPaged(suffix: string, page: { size: string, page: string, sort: string }, filters?: { name: string, val: any }[]): void {
    this.loadingSubject.next(true);

    this.service.getAll(suffix, page, filters)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((value: any[]) => {
        this.totalPages = 1;
        this.totalElements = value.length;
        this.listSubject.next(value);
      });
  }

  public getData(): any[] {
    return this.listSubject.value;
  }

  public updateData(data: any[]) {
    this.listSubject.next(data);
  }
}
