import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CustomDateRangeModel, TimeFrames} from './custom-date-range.model';
import {DateRange} from '@angular/material/datepicker';
import {CustomDateRangeChangeEventModel} from './custom-date-range-change-event.model';
import {LoadingService} from "../../../core/services/loading.service";

@Component({
  selector: 'app-custom-date-filter',
  templateUrl: './custom-date-filter.component.html',
  styleUrls: ['./custom-date-filter.component.scss']
})
export class CustomDateFilterComponent implements OnInit {

  @Input() id!: string;
  @Input() presetsShowing: boolean = true;
  @Input() datePickerShowing: boolean = true;
  @Input() showBorder: boolean = true;
  @Input() customLabel?: string | null;
  @Input() initialPreset?: string;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() showClearButton: boolean = false;
  @Input() compactPresets: boolean = false;

  @Input() customDateRangePresets?: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 2, TimeFrames.DAYS),
    new CustomDateRangeModel('2', 1, TimeFrames.WEEK),
    new CustomDateRangeModel('3', 1, TimeFrames.MONTH)
  ];
  @Output() dateRange: EventEmitter<CustomDateRangeChangeEventModel> = new EventEmitter<CustomDateRangeChangeEventModel>();
  selectedPreset: string = '';
  dateOption!: string;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(public loader: LoadingService) {
  }

  ngOnInit() {
    if (this.initialPreset) {
      this.dateOption = this.initialPreset;
      this.broadcastDateRange({value: this.initialPreset});
    }
  }

  public broadcastDateRange(event: {value: any}) {
    this.setSelectedPreset(event);
    //Check if a preset has been selected
    if (this.dateOption != 'custom') {

      let dateRangeModel = this.customDateRangePresets!.find(preset => {
        return (preset.presetId === this.selectedPreset)
      });
      if (dateRangeModel === undefined) {
        dateRangeModel = new CustomDateRangeModel('0', 1, TimeFrames.DAYS);
      }
      this.dateRange.emit(new CustomDateRangeChangeEventModel(this.id, dateRangeModel.getDateRange()));
    } else {
      let startDate = this.range.controls.start === null ? new Date() : this.range.controls.start.value;
      let endDate = this.range.controls.end === null ? new Date() : this.range.controls.end.value;
      this.dateRange.emit(new CustomDateRangeChangeEventModel(
        this.id,
        new DateRange<Date>(startDate, endDate)
      ));
    }
  }

  public toggleDatePickerShowing(): void {
    this.datePickerShowing = !this.datePickerShowing;
  }

  public togglePresetsShowing(): void {
    this.presetsShowing = !this.presetsShowing;
  }

  setSelectedPreset($event: { value: any }) {
    this.selectedPreset = $event.value;
  }

  selectCustomDate(update: boolean) {
    this.dateOption = 'custom';
    if (update) {
      this.broadcastDateRange({value: ''});
    }
  }

  clearFilters(): void {
    this.range.get('start')?.setValue(null);
    this.range.get('end')?.setValue(null);

    if (this.initialPreset) {
      this.dateOption = this.initialPreset;
      this.selectedPreset = this.initialPreset;
      this.broadcastDateRange({value: this.initialPreset});
    } else {
      this.selectedPreset = '';
      this.dateRange.emit(new CustomDateRangeChangeEventModel(
        this.id,
        new DateRange<Date>(null, null)
      ));
    }
  }
}
