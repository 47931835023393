<div fxLayout="column" fxLayoutGap="20px">
  <div class="full-width px-2" fxLayout="row" fxLayoutAlign="space-between center"
       *ngIf="(screenSize.screenWidth | async) && displayBestStats">
    <div class="title-small">Best Stats</div>
    <mat-icon>more_vert</mat-icon>
  </div>

  <div class="dashboard-card full-width mb-3" fxLayout="column" fxLayout.gt-xs="row"
       fxLayoutAlign="start start" fxLayoutAlign.gt-xs="space-between center" fxLayoutGap="10px"
       *ngIf="displayBestStats">
    <div fxLayout="column" class="full-width" fxLayoutGap="7px" *ngFor="let stat of bestMonthlyStats">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="25px">
        <div  [class]="(screenSize.mobileView$ | async) ? 'heading-bold' : 'subtitle-default'">{{ stat.statName ? stat.statName : null }}</div>
        <div [class]="(screenSize.mobileView$ | async) ? 'body-1-default' : 'heading-bold'">{{ stat ? stat.statFormattedValue : '-' }}</div>
      </div>
      <div [class]="(screenSize.mobileView$ | async) ? 'body-1-regular-1' : 'heading-regular'">{{ stat ? stat.statMonth : '-' }}</div>
    </div>
  </div>
</div>

