<div
  class="px-3 py-2 pb-3 full-screen-height"
  fxLayout="column"
  fxLayout.xs="row"
  fxLayoutAlign="center center"
>
  <div class="full-width flex-column pre-auth-header-div" fxLayoutAlign="center center">
    <h1 class="pre-auth-header">Your brand information</h1>
    <h2 class="pre-auth-sub-header">Tell us about your brand and where we can find you online</h2>
  </div>
  <div class=" p3 full-width flex-row" fxLayoutAlign="center center">
    <form
      [formGroup]="registrationForm"
      class="pre-auth-form"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="5px"
      fxLayoutGap.gt-sm="8px"
    >
      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
        <mat-label>Company name</mat-label>
        <input formControlName="companyName" matInput/>
        <mat-error
          *ngIf="registrationForm.get('companyName')!.hasError('required')"
        >Company name is required
        </mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-select-field" floatLabel="always">
        <mat-label>Industry</mat-label>
        <mat-select [(value)]="selectedIndustries" class="custom-select-field-value" formControlName="industries"
                    multiple>
          <mat-optgroup *ngFor="let group of industryList" class="btn-cursor">
            <mat-label (click)="groupClicked(group)" class="custom-select-field-section" fxLayout="row" fxLayoutAlign="start center"
                       fxLayoutGap="5px">
              <span>{{group.name}}</span>
              <mat-icon class="btn-cursor">add</mat-icon>
            </mat-label>
            <mat-option (click)="optionClicked(group, ind)" *ngFor="let ind of group.industries" [value]="ind.code"
                        class="custom-select-field-option">{{ ind.name }}</mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error *ngIf="registrationForm.get('industries')!.hasError('required')"
        >Industry name is required
        </mat-error
        >
      </mat-form-field>


      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
        <mat-label>Website</mat-label>
        <input formControlName="websiteUrl" matInput/>
        <mat-error *ngIf="registrationForm.get('websiteUrl')!.hasError('websiteUrlInvalid')">
          Website is not valid
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
        <mat-label>Social Media Link</mat-label>
        <input
          formControlName="socialMediaUrl"
          id="socialMediaUrl"
          matInput
        />
        <mat-error *ngIf="registrationForm.get('socialMediaUrl')!.hasError('socialMediaUrlInvalid')"
        >Social Media link is not valid
        </mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always">
        <mat-label class="form-label-soko">Mobile</mat-label>
        <input [pattern]="mobNumberPattern" formControlName="mobile" matInput type="text">
        <mat-error *ngIf="registrationForm.get('mobile')!.hasError('required')">
          Phone number is required
        </mat-error>
        <mat-error *ngIf="registrationForm.get('mobile')!.hasError('pattern')">
          Mobile number not valid, please enter 9 digits
        </mat-error>
      </mat-form-field>

      <mat-error *ngIf="errorMsg" class="pb-2">{{ errorMsg }}</mat-error>
      <button
        (click)="submit()"
        [disabled]="registrationForm.invalid || loading || (loader.loading$ | async)"
        class="button-primary"
        mat-flat-button
      >
        <div class="button-text">{{ loading ? "Working..." : "Sign Up" }}</div>
        <mat-icon *ngIf="loading">
          <mat-spinner diameter="20"></mat-spinner>
        </mat-icon>
      </button>
    </form>
  </div>
</div>
