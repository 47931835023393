<form class="full-width info-card-grey form-info-card px-0" [formGroup]="loginForm" fxLayout="column"
      fxLayoutAlign="center center" fxLayoutGap="30px">

  <mat-error class="body-1-error mb-0 mt-3" *ngIf="loginForm.get('password')!.hasError('login-failed')">Username or password is incorrect</mat-error>

  <app-application-form-row fieldLabel="Email" errorMessage="Email is required" fxFlexOffset="30px"
                            [showError]="hasError('email')">
    <input matInput formControlName="email" placeholder="Email address" required="true">
    <mat-error class="link-error" *ngIf="hasError('email','pattern')">Email format required, e.g. sample-email&#64;domain.com</mat-error>
  </app-application-form-row>

  <app-application-form-row errorMessage="Password is required" fieldLabel="Password"
                            [showError]="hasError('password')">
    <input matInput #password [type]="hidePassword ? 'password' : 'text'" formControlName="password" placeholder="Input text" matSuffix/>
    <mat-icon (click)="hidePassword = !hidePassword" *ngIf="hidePassword" class="btn-cursor icon-password-suffix" matSuffix>visibility</mat-icon>
    <mat-icon (click)="hidePassword = !hidePassword" *ngIf="!hidePassword" class="btn-cursor icon-password-suffix" matSuffix>visibility_off</mat-icon>
  </app-application-form-row>

  <app-application-form-row-label [lastRow]="true">
    <div class="subtitle-regular">
      Don't have an account? <span class="heading-primary-1 btn-cursor" (click)="gotoSignUp()">Sign up</span>
    </div>
  </app-application-form-row-label>
</form>

