<div class="px-3 py-2 pb-3 password-reset-gap-container full-screen-height" fxLayout="column" fxLayoutAlign="center center"
     fxLayoutGap="15px">

  <div class="full-width flex-column pre-auth-header-div" fxLayoutAlign="center center">
    <h1 class="pre-auth-header"> Please verify your email</h1>
    <h2 class="pre-auth-sub-header" style="text-align: center"> We have sent a One Time Pin to {{this.userEmail}}</h2>
  </div>

  <div fxFlexOffset="30px"></div>

  <div class="flex-row">
    <div class=" p3 full-width flex-column gap-important" fxLayoutAlign="center center" fxLayoutGap="15px">
      <div class="otp-text" fxFlexOffset="10px" fxFlexOffset.gt-xs="15px">
        <ng-otp-input
          #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="{
              length: 4,
              allowNumbersOnly: true,
              containerClass: 'otp-wrapper-element',
              inputClass: 'otp-input-element'
            }"
        ></ng-otp-input>
      </div>
      <mat-error *ngIf="errMessage">{{ errMessage }}</mat-error>

      <div class="" fxFlexOffset="30px" fxFlexOffset.gt-xs="30px" fxLayoutGap="20px">
        <button style="width: 200px" (click)="onVerify()" [disabled]="len < 4 || (loader.loading$ | async)" class="button-primary" mat-flat-button>
          <div class="button-text">{{ (loader.loading$ | async) ? 'Working...' : 'Verify'}}</div>
          <mat-icon *ngIf="(loader.loading$ | async)">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </div>

      <div class="p-2 heading-regular" *ngIf="!(loader.loading$ | async) && !disableResend">
        Didn’t receive an OTP?&nbsp;
        <span (click)="onResendOTP()" class="btn-cursor heading-primary-1">Resend OTP.</span>
        <mat-error class="link-error" *ngIf="errMessage">{{ errMessage }}</mat-error>
      </div>

      <div fxLayout="row" class="p-2 heading-regular" fxLayoutGap="10px"
           *ngIf="(loader.loading$ | async) || disableResend">Didn’t receive an OTP?&nbsp;
        <span class="btn-cursor heading-regular"
              [matTooltip]="'OTP resend with be allowed at ' + dateUtils.displayDateWithTime(allowResendTime)">Resend OTP.</span>
        <mat-spinner color="primary" diameter="15"></mat-spinner>
      </div>
    </div>
  </div>
</div>

