<div fxLayout="column">
  <app-page-header [banner]="false"
                   [section]="'Brand information'"
                   [title]="'Manage your brand profile'"
                   [description]="'On this settings page you can see your general brand information. You can update your details at any time to improve your image and other communications.'">
  </app-page-header>

  <div fxLayout="column" class="page-content page-padding full-screen-height-page-default">
    <div fxFlexOffset="10px" fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="start center">
      <span class="subtitle-default">Brand Information</span>
      <button (click)="toggleEdit()" *ngIf="!edit" [disabled]="loader.loading$ | async" class="button-action"
              mat-flat-button>
        <div class="button-text">Update</div>
      </button>
      <button (click)="saveUpdatesToBrandInformation()" *ngIf="edit" [disabled]="loader.loading$ | async"
              class="button-action" mat-flat-button>
        <div class="button-text">Submit</div>
      </button>
      <button (click)="toggleEdit()" *ngIf="edit" [disabled]="loader.loading$ | async"
              class="button-action-cancel" mat-flat-button>
        <div class="button-text">Cancel</div>
      </button>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px">
      <div class="info-card" fxLayout="column">
        <span class="heading-default">General information</span>
        <div [formGroup]="retailerForm" fxFlexOffset="20px" fxLayout="row" fxLayoutGap="15px">
          <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
            <mat-label>System ID</mat-label>
            <input matInput type="text" [value]="this.currentRetailer?.id!.toString()" disabled/>
          </mat-form-field>
        </div>
        <div fxLayout="row" [formGroup]="retailerForm" fxLayoutGap="15px" fxFlexOffset="20px">
          <mat-form-field [ngClass]="edit ? 'custom-text-field' : 'custom-text-autofill'"
                          appearance="outline" floatLabel="always" fxFlex="50">
            <mat-label>Company Name</mat-label>
            <input matInput type="text" formControlName="companyName"/>
          </mat-form-field>
          <mat-form-field [ngClass]="edit ? 'custom-text-field' : 'custom-text-autofill'"
                          appearance="outline" floatLabel="always" fxFlex="50" hideRequiredMarker>
            <mat-label>Website</mat-label>
            <input formControlName="websiteUrl" matInput type="text"/>
          </mat-form-field>
        </div>
        <div [formGroup]="retailerForm" fxFlexOffset="20px" fxLayout="row" fxLayoutGap="15px">
          <mat-form-field *ngIf="edit" appearance="outline" class="custom-select-field"
                          floatLabel="always" fxFlex="50" hideRequiredMarker>
            <mat-label>Industry</mat-label>
            <mat-select class="custom-select-field-section" formControlName="industries" multiple #industry>
              <mat-optgroup *ngFor="let group of industryList" [label]="group.name">
                <mat-option class="custom-select-field-option" *ngFor="let ind of group.industries" [value]="ind.code">
                  {{ ind.name }}</mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error *ngIf="retailerForm.get('industries')!.hasError('required')">
              Industry is required
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!edit" appearance="outline" class="custom-select-field custom-text-autofill"
                          floatLabel="always" fxFlex="50" hideRequiredMarker>
            <mat-label>Industry</mat-label>
            <mat-select [disabled]="true" class="custom-select-field-section mt-1" formControlName="industries"
                        multiple>
              <mat-optgroup *ngFor="let group of industryList" [label]="group.name">
                <mat-option class="custom-select-field-option" *ngFor="let ind of group.industries" [value]="ind.code">
                  {{ ind.name }}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>

          <mat-form-field [ngClass]="edit ? 'custom-text-field' : 'custom-text-autofill'"
                          appearance="outline" floatLabel="always" fxFlex="50">
            <mat-label>Physical Address</mat-label>
            <input (onAutocompleteSelected)="onAddressAutocompleteSelected($event)" (onLocationSelected)="onAddressLocationSelected($event)"
                   formControlName="physicalAddress"
                   matGoogleMapsAutocomplete
                   matInput
                   type="text"/>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="full-width" fxFlexOffset="30px" fxLayout="row" fxLayoutGap="20px">
      <app-retailer-contacts class="full-width"></app-retailer-contacts>

    </div>
    <div class="full-width" fxFlexOffset="30px" fxLayout="column" fxLayoutGap="20px">
      <app-social-media #socialMediaComponent class="full-width"></app-social-media>
    </div>
  </div>

</div>
