import {Component, ViewChild} from '@angular/core';

import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {LoadingService} from "../../../../core/services/loading.service";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {ErrorPopupComponent} from '../../../../shared/components/dialogs/error-popup/error-popup.component';
import {IconPopupComponent} from '../../../../shared/components/dialogs/icon-popup/icon-popup.component';
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {UpdatePasswordService} from '../service/update-password.service';


@Component({
  selector: 'app-verify-update-password-otp',
  templateUrl: './verify-update-password-otp.component.html',
  styleUrls: ['./verify-update-password-otp.component.scss'],
})
export class VerifyUpdatePasswordOtpComponent {
  public otpForm;
  public errMessage: string | null;
  public len = 0;

  disableResend = true;
  delayInMinutes = 1;
  allowResendTime = new Date();

  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  otpInputConfig = {
    length: 4,
    allowNumbersOnly: true,
    containerClass: 'otp-wrapper-element',
    inputClass: 'otp-input-element'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: UpdatePasswordService,
    private fb: FormBuilder,
    private dialogService: MatDialog,
    public loader: LoadingService,
    private context: CurrentContextService,
    public dateUtils: DateUtilsService
  ) {
    this.otpForm = this.resetForm();
    this.errMessage = null;

    this.resendDelay();
  }

  onOtpChange(event: string) {
    this.otpForm.get('pin')!.setValue(event);
    this.len = this.otpForm.value.pin.toString().length;
  }

  // call the update password service using vars in the context
  onVerify() {
    const otp = this.otpForm.value.pin;
    this.service.updateOtp(otp);

    this.errMessage = null;
    lastValueFrom(this.service.updatePassword())
      .then(
        (res) => {
          this.dialogService.open(IconPopupComponent, {
            height: '300px',
            width: '475px',
            data: {
              header: 'Password Reset',
              description: 'Your password has successfully been updated!',
              imageSrc: '../../../../assets/icon-verification-dark.svg'
            },
          });
          this.router.navigate([`${this.context.getCurrentLocationCode()}/login`]);
        },
        (err) => {
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.otpForm.get('otp')!.setValue(null);
              }
              break;
            default:
              console.log(err);
              this.dialogService.open(ErrorPopupComponent, {
                height: '300px',
                width: '450px',
                data: {
                  header: 'Oops!',
                  description: 'An unexpected error occurred',
                },
              });
          }
        }
      );
  }

  resendDelay() {
    this.disableResend = true;
    const delay = this.delayInMinutes * 60 * 1000;
    this.allowResendTime = new Date(Date.now() + delay);
    setTimeout(() => {
      this.disableResend = false;
    }, delay);
  }

  onResendOTP(): void {
    this.service.requestNewOTP(true).subscribe(
      (res) => {
        this.resendDelay();
        this.dialogService.open(IconPopupComponent, {
          height: '300px',
          width: '475px',
          data: {
            header: 'Resend OTP',
            description: 'We have sent a One Time Pin to the provided email.',
            imageSrc: '../../../../assets/icon-verification-dark.svg'
          },
        });
      },
      (err) => {
        switch (err.status) {
          case 400:
            if ('ValidationException' === err.headers.get('error_code')) {
              this.errMessage = '' + err.headers.get('error_message');
              this.otpForm.get('pin')!.setValue(null);
              this.ngOtpInputRef.setValue(null);
            }
            break;
          default:
            console.log(err);
            this.dialogService.open(ErrorPopupComponent, {
              height: '300px',
              width: '450px',
              data: {
                header: 'Oops!',
                description: 'An unexpected error occurred',
              },
            });
        }
      }
    );
  }

  private resetForm(): FormGroup {
    return this.fb.group({
      pin: [null, Validators.required],
    });
  }
}
