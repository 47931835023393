import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {
  CustomDateRangeChangeEventModel
} from '../../../shared/components/custom-date-filter/custom-date-range-change-event.model';
import {CustomDateRangeModel, TimeFrames} from '../../../shared/components/custom-date-filter/custom-date-range.model';
import {DateUtilsService} from '../../../shared/services/dateUtils.service';
import {PaginatorService} from '../../../shared/services/paginator.service';
import {ScreenSizeService} from "../../../shared/services/screen-size.service";
import {UtilsService} from '../../../shared/services/utils.service';
import {ChartOptionsService} from '../../dashboards/chart-options/chart-options.service';
import {
  DashboardsInfoDialogComponent
} from '../../dashboards/common/dashboards-info-dialog/dashboards-info-dialog.component';
import {
  DashboardsRetailerReportService
} from '../../dashboards/dashboards-retailer-report/dashboards-retailer-report.service';
import {RetailerProductPerformance} from '../../dashboards/dashboards.model';
import {SalesReportChartConfig} from './sales-report-chart-config';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',

})
export class SalesReportComponent implements AfterViewInit {

  @ViewChild('bestSellersPaginator') bestSellersPaginator!: MatPaginator;
  @ViewChild('forecastPaginator') forecastPaginator!: MatPaginator;
  displayedColumnsSellers: string[] = ['icon', 'productName', 'productSku', 'quantitySold', 'saleAmount'];
  displayedColumnsForecast: string[] = ['icon', 'productName', 'productSku', 'quantitySold', 'avgQuantitySold', 'currentInventoryLevel', 'weeksToLast'];
  bestSellersDescription: string = 'This table shows how well each product is performing. The information can be sorted by any of the columns to understand the contribution each product makes towards total sales.';
  inventoryForecastDescription: string = 'This table estimates how many weeks the current stock will last, based on actual inventory data and quantity sold for the selected period. This calculation is dependent on the accuracy of the data recorded in the point-of-sale software.';
  bestSellersLoading: boolean = false;
  inventoryForecastLoading: boolean = false;
  chartConfig: SalesReportChartConfig;
  dashboardService: DashboardsRetailerReportService;
  bestSellersDatasource!: MatTableDataSource<RetailerProductPerformance>;
  inventoryForecastDatasource!: MatTableDataSource<RetailerProductPerformance>;
  customDateRangePresets2: CustomDateRangeModel[] = [
    new CustomDateRangeModel('past_year', 1, TimeFrames.YEAR, 'Past year'),
    new CustomDateRangeModel('past_6_months', 6, TimeFrames.MONTH, 'Past 6 months'),
    new CustomDateRangeModel('past_3_months', 3, TimeFrames.MONTH, 'Past 3 months'),
    new CustomDateRangeModel('this_month', 0, TimeFrames.MONTH, 'This month'),
    new CustomDateRangeModel('yesterday', 1, TimeFrames.DAYS, 'Yesterday'),
  ];
  topFilter: number = 100;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              httpClient: HttpClient,
              private chartOptionsService: ChartOptionsService,
              private context: CurrentContextService,
              private matDialog: MatDialog,
              private paginatorService: PaginatorService,
              public screenSize: ScreenSizeService,) {
    this.dashboardService = new DashboardsRetailerReportService(httpClient, context);
    this.chartConfig = new SalesReportChartConfig(this, chartOptionsService, this.dateUtils);

  }

  @ViewChild('sortProducts', {read: MatSort, static: false}) set productsContent(sort: MatSort) {
    if (this.bestSellersDatasource) this.bestSellersDatasource.sort = sort;
  }

  @ViewChild('sortInventory', {read: MatSort, static: false}) set inventoryContent(sort: MatSort) {
    if (this.inventoryForecastDatasource) this.inventoryForecastDatasource.sort = sort;
  }

  ngAfterViewInit(): void {
    this.bestSellersPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.forecastPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
  }


  loadBestSellersList(event: CustomDateRangeChangeEventModel): void {
    this.bestSellersLoading = true;
    const start = event.dateRange.start;
    const end = event.dateRange.end;
    const topPercent = this.topFilter;
    const next = this.chartConfig.bestSellersList.next;
    const error = this.chartConfig.bestSellersList.error;
    this.dashboardService.refreshBestSellers(this.dateUtils.displayShortDate(start)!,
      this.dateUtils.displayShortDate(end)!, topPercent, next, error);
  }

  loadInventoryForecastList(event: CustomDateRangeChangeEventModel): void {
    this.inventoryForecastLoading = true;
    const start = event.dateRange.start;
    const end = event.dateRange.end;
    const next = this.chartConfig.inventoryForecastList.next;
    const error = this.chartConfig.inventoryForecastList.error;
    this.dashboardService.refreshInventoryForecast(this.dateUtils.displayShortDate(start)!,
      this.dateUtils.displayShortDate(end)!, next, error);
  }

  getBestSellerDateRange(event: CustomDateRangeChangeEventModel) {
    if (event.dateRange.start && event.dateRange.end) {
      this.loadBestSellersList(event);
    }
  }

  getInventoryDateRange(event: CustomDateRangeChangeEventModel) {
    if (event.dateRange.start && event.dateRange.end) {
      this.loadInventoryForecastList(event);
    }
  }


  openInfoDialog() {
    this.matDialog.open(DashboardsInfoDialogComponent, {
      panelClass: 'dialog-large',
      height: '450px',
      width: '600px',
      backdropClass: 'dialog-backdrop-opaque',
    })
  }

  calculateTotalBestSellersSales(): string {
    if (this.bestSellersDatasource && this.bestSellersDatasource.data) {
      let totalSales: number = this.bestSellersDatasource.data
        .map(item => {
          return item.saleAmount
        })
        .filter(item => item != null || false)
        .reduce((acc, value) => acc + value, 0);

      return this.chartOptionsService.displayRandAmount(totalSales);
    }
    return "R -";
  }
}
