<div class="page-content" fxLayout="column">

  <app-page-header [banner]="true"
                   [fullHeight]="false"
                   [description]="'Watch tutorials, browse articles or contact an agent to help your retailers fulfill sales and manage inventory.'"
                   [title]="'Point-of-sale Help Center'">
  </app-page-header>

  <div class="questions-section p-4" [class.pt-0]="(screenSize.mobileView$ | async)">
    <div class="grid-list" fxLayout="column" fxLayout.gt-sm="row wrap">
      <div class="grid-list-item" fxLayout="column" *ngFor="let cat of allCategories; let i = index; let last = last" fxFlex="33.3">
        <div fxLayout="row" fxLayoutAlign="space-between start" style="height: 100%">
          <div class="px-3 py-4" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
            <div class="subtitle-default">{{cat.categoryName}}</div>
            <div class="ps-2" fxLayout="column" fxLayoutGap="10px">
              <div class="btn-cursor" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px"
                   *ngFor="let ques of questionList(cat)" (click)="openLink(ques.link)">
                <mat-icon class="article-icon material-icons-outlined">article</mat-icon>
                <div class="heading-regular">{{ques.articleName}}</div>
              </div>
            </div>
            <div class="body-1-primary btn-cursor" (click)="cat.showAll = !cat.showAll" *ngIf="cat.questions && cat.questions.length > 3">{{cat.showAll ? 'Show less' : 'Show all'}}</div>
          </div>
          <mat-divider vertical *ngIf="(i + 1) % 3 !== 0 && !(screenSize.mobileView$ | async)" style="height: 100%"></mat-divider>
        </div>
        <mat-divider class="full-width" *ngIf="(allCategories.length - 3) > i || ((screenSize.mobileView$ | async) && !last)"></mat-divider>
      </div>
    </div>
  </div>
</div>
