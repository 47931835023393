<div class="p-3 full-width password-reset-gap-container full-screen-height" fxLayout="column"
     fxLayoutAlign="center center"
     fxLayoutGap="15px">

  <div class="full-width flex-row reset-password-header-div" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      <h1 class="title-large">Enter your OTP</h1>
      <h2 class="subtitle-regular" style="text-align: center">We have sent you an One Time Pin</h2>
    </div>
  </div>

  <div fxLayout="row" fxFlexOffset="25px">
    <div class="p-3 full-width flex-column" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">

      <div class="otp-text" fxFlexOffset="10px">
        <ng-otp-input #ngOtpInput
                      (onInputChange)="onOtpChange($event)"
                      [config]="otpInputConfig">
        </ng-otp-input>
      </div>

      <div fxFlexOffset="50px">
        <button (click)="onVerify()" [disabled]="len < 4 || (loader.loading$ | async)" class="button-primary"
                mat-flat-button style="width: 200px">
          <div class="button-text" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            {{ (loader.loading$ | async) ? 'Working...' : 'Update new password' }}
            <mat-icon *ngIf="(loader.loading$ | async)">
              <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
          </div>
        </button>
      </div>

      <div class="p-2 heading-regular" *ngIf="!(loader.loading$ | async) && !disableResend">
        Didn’t receive an OTP?&nbsp;
        <span (click)="onResendOTP()" class="btn-cursor heading-primary-1">Resend OTP.</span>
        <mat-error class="link-error" *ngIf="errMessage">{{ errMessage }}</mat-error>
      </div>

      <div fxLayout="row" class="p-2 heading-regular" fxLayoutGap="10px"
           *ngIf="(loader.loading$ | async) || disableResend">Didn’t receive an OTP?&nbsp;
        <span class="btn-cursor heading-regular"
              [matTooltip]="'OTP resend with be allowed at ' + dateUtils.displayDateWithTime(allowResendTime)">Resend OTP.</span>
        <mat-spinner color="primary" diameter="15"></mat-spinner>
      </div>

    </div>
  </div>
  <div>
    <app-footer-links [showCopyrightOnly]="true"></app-footer-links>
  </div>
</div>
