<div fxLayout="column" fxLayoutGap="15px">

  <app-page-header [banner]="false" [description]="'The sales report module allows you to see all product sales for a date range for your retail offering. Everything happens automatically, your work here is done!'" [section]="'Sales Report'"
                   [title]="'Keep an eye on product sales'"></app-page-header>

  <div class="page-content page-padding" fxLayout="column" fxLayoutGap="10px" fxLayoutGap.gt-xs="20px">

    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">

      <div class="full-width px-2" fxFlex="30" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title-small">Best Sellers</div>
        <mat-icon>more_vert</mat-icon>
      </div>

      <div fxFlex="70" fxLayout="column" fxLayoutGap="10px" fxFlexAlign="end">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="heading-default" *ngIf="!(screenSize.mobileView$ | async)">Over this period</div>
          <app-custom-date-filter (dateRange)="getBestSellerDateRange($event)"
                                  [customDateRangePresets]="customDateRangePresets2"
                                  [initialPreset]="'yesterday'"
                                  [showBorder]="false"
                                  [compactPresets]="(screenSize.mobileView$ | async)!"></app-custom-date-filter>
        </div>
      </div>
    </div>

    <div (click)="openInfoDialog()" class="btn-cursor" fxFlexOffset="7px"
         fxLayout="row" fxLayoutAlign="end center">
      <span class="link-default btn-cursor" style="font-size: 9px !important;">Not seeing all the data?</span>
    </div>

    <div fxFlexOffset="10px" fxFlexOffset.gt-xs="20px" fxLayout="row">

      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="subtitle-default ps-2">Top Performing Products by Sales</span>
        <span class="body-1-regular-2 px-2">{{ bestSellersDescription }}</span>
        <span class="body-1-regular-2 px-2" style="align-self: flex-end"><strong style="font-size:16px">Total sales for period {{ calculateTotalBestSellersSales() }}</strong></span>
        <table #sortProducts *ngIf="!bestSellersLoading; else canvasLoadingSpinner" [dataSource]="bestSellersDatasource" class="dashboard-table"
               mat-table
               matSort>

          <ng-container matColumnDef="icon">
            <th *matHeaderCellDef mat-header-cell [hidden]="(screenSize.mobileView$ | async)"></th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell [hidden]="(screenSize.mobileView$ | async)">
              <mat-icon class="material-icons-outlined">local_offer</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="productName">
            <th *matHeaderCellDef mat-header-cell> Product Name</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayString(element.productName, 60) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="productSku">
            <th *matHeaderCellDef mat-header-cell> Product Code</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayString(element.productSku, 60) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="quantitySold">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Quantity Sold</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayNumber(element.quantitySold) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="saleAmount">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Sales</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayRandAmount(element.saleAmount) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="contributingPercentage">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Contributing to total sales</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayNumber(element.contributingPercentage) }}%
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsSellers" class="custom-table-header" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumnsSellers;" class="custom-table-row" mat-row></tr>
        </table>

        <mat-paginator #bestSellersPaginator [length]="bestSellersDatasource ? bestSellersDatasource.data.length : 0"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       [hidePageSize]="(screenSize.mobileView$ | async)"
                       [pageSize]="(screenSize.mobileView$ | async) ? 5 : 20"
                       class="custom-paginator"></mat-paginator>
      </div>
    </div>

    <mat-divider></mat-divider>


    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">

      <div class="full-width px-2" fxFlex="30" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title-small">Inventory Forecast</div>
        <mat-icon>more_vert</mat-icon>
      </div>

      <div fxFlex="70" fxLayout="column" fxLayoutGap="10px" fxFlexAlign="end">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="heading-default" *ngIf="!(screenSize.mobileView$ | async)">Based on sales over this period</div>
          <app-custom-date-filter (dateRange)="getInventoryDateRange($event)"
                                  [customDateRangePresets]="customDateRangePresets2"
                                  [initialPreset]="'yesterday'"
                                  [showBorder]="false"
                                  [compactPresets]="(screenSize.mobileView$ | async)!"></app-custom-date-filter>
        </div>
      </div>
    </div>

    <div (click)="openInfoDialog()" class="btn-cursor" fxFlexOffset="7px"
         fxLayout="row" fxLayoutAlign="end center">
      <span class="link-default btn-cursor" style="font-size: 9px !important;">Not seeing all the data?</span>
    </div>

    <div class="pb-5" fxFlexOffset="20px" fxLayout="row">
      <div class="full-width" fxLayout="column" fxLayoutGap="7px">
        <span class="subtitle-default ps-2">Inventory Forecast based on Sales History</span>
        <span class="body-1-regular-2 px-2">{{ inventoryForecastDescription }}</span>
        <table #sortInventory *ngIf="!inventoryForecastLoading; else canvasLoadingSpinner" [dataSource]="inventoryForecastDatasource" class="dashboard-table"
               mat-table
               matSort>

          <ng-container matColumnDef="icon">
            <th *matHeaderCellDef mat-header-cell [hidden]="(screenSize.mobileView$ | async)"></th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell [hidden]="(screenSize.mobileView$ | async)">
              <mat-icon class="material-icons-outlined">inventory_2</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="productName">
            <th *matHeaderCellDef mat-header-cell> Product Name</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayString(element.productName, 60) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="productSku">
            <th *matHeaderCellDef mat-header-cell> Product Code</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayString(element.productSku, 60) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="quantitySold">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Quantity Sold</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayNumber(element.quantitySold) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="avgQuantitySold">
            <th *matHeaderCellDef mat-header-cell mat-sort-header [hidden]="(screenSize.mobileView$ | async)"> Average Quantity Sold Per Week</th>
            <td *matCellDef="let element" class="custom-table-cell" [hidden]="(screenSize.mobileView$ | async)"
                mat-cell> {{ utils.displayDecimal(element.avgQuantitySold, 1) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="currentInventoryLevel">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> Actual Inventory</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{ utils.displayNumber(element.currentInventoryLevel) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="weeksToLast">
            <th *matHeaderCellDef mat-header-cell mat-sort-header [hidden]="(screenSize.mobileView$ | async)"> Weeks to last</th>
            <td *matCellDef="let element" class="custom-table-cell" [hidden]="(screenSize.mobileView$ | async)"
                mat-cell> {{ utils.displayDecimal(element.weeksToLast, 1) }}
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumnsForecast" class="custom-table-header" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumnsForecast;" class="custom-table-row" mat-row></tr>
        </table>

        <mat-paginator #forecastPaginator [length]="inventoryForecastDatasource ? inventoryForecastDatasource.data.length : 0"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       [hidePageSize]="(screenSize.mobileView$ | async)"
                       [pageSize]="(screenSize.mobileView$ | async) ? 5 : 20"
                       class="custom-paginator"></mat-paginator>
      </div>
    </div>

    <ng-template #canvasLoadingSpinner>
      <div fxLayout="row" fxLayoutAlign="center center" style="height: 350px">
        <mat-spinner color="primary" diameter="30"></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
