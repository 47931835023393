<div class="px-3 py-2 pb-3 full-width full-screen-height" fxLayout="column" fxLayoutAlign="center center">

  <div class="full-width flex-row">
    <div class="full-width pre-auth-header-div" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      <h1 class="title-large" fxFlexOffset.gt-xs="20px">Check your mail</h1>
      <h2 class="subtitle-regular" style="text-align: center">We have sent you a reset password OTP on your registered email address.</h2>
    </div>
  </div>
  <div class="full-width flex-row" fxLayoutAlign="center center" fxFlexOffset="90px">
    <form class="pre-auth-form" fxFlexOffset.gt-xs="20px">
      <button (click)="onSubmit()" [disabled]="(loader.loading$ | async)" class="button-primary" mat-button>
        <div class="button-text" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          {{ (loader.loading$ | async) ? 'Working...' : 'Continue'}}
          <mat-icon *ngIf="(loader.loading$ | async)">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </div>
      </button>
    </form>
  </div>
  <div class="p-2 heading-regular" *ngIf="!(loader.loading$ | async) && !disableResend">
    Didn’t receive an OTP?&nbsp;
    <span (click)="onResendOTP()" class="btn-cursor heading-primary-1">Resend OTP.</span>
    <mat-error class="link-error" *ngIf="errMessage">{{errMessage}}</mat-error>
  </div>

  <div fxLayout="row" class="p-2 heading-regular" fxLayoutGap="10px"
       *ngIf="(loader.loading$ | async) || disableResend">Didn’t receive an OTP?&nbsp;
    <span class="btn-cursor heading-regular" [matTooltip]="'OTP resend with be allowed at ' + dateUtils.displayDateWithTime(allowResendTime)">Resend OTP.</span>
    <mat-spinner color="primary" diameter="15"></mat-spinner>
  </div>

  <app-footer-links [showCopyrightOnly]="true"></app-footer-links>

</div>
