<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="10px" fxLayoutGap.gt-xs="20px">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxFlex="30">

    <div class="full-width px-2" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-small">{{ sectionName }}</div>
      <mat-icon>more_vert</mat-icon>
    </div>

    <div class="full-width py-3" fxLayout="column" fxFlexOffset="10px" fxFlexOffset.gt-xs="30px"
         fxLayoutGap="15px" fxLayoutGap.gt-xs="30px">
      <app-dashboards-stat-card [chart]="chart1" [chartStats]="chartStats1"
                                [icon]="'shopping_bag'" [fullNumber]="true">
      </app-dashboards-stat-card>

      <app-dashboards-stat-card [chart]="chart2!" [chartStats]="chartStats2!"
                                [icon]="'shopping_bag'" [fullNumber]="true">
      </app-dashboards-stat-card>
    </div>

  </div>

  <div class="full-width" fxLayout="column" fxFlex="70">
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="15px"
         fxLayoutAlign="start start" fxLayoutAlign.gt-xs="space-between center">

      <div class="subtitle-default ps-2">{{ chart1.chartName }}</div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="dateFilterType == 'picker'">
        <mat-form-field class="custom-date-picker" appearance="outline">
          <input type="hidden" placeholder="Month" [matDatepicker]="picker1" [value]="date1"/>
          <input matInput type="text" [value]="pickerDisplayValue(date1!)"/>
          <mat-datepicker-toggle matIconSuffix [for]="picker1">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker1 [startView]="getPickerStartView"
                          (yearSelected)="selectYear($event, picker1, 1)"
                          (monthSelected)="selectMonth($event, picker1, 1)"></mat-datepicker>
        </mat-form-field>

        <div class="body-1-regular-2">and</div>

        <mat-form-field class="custom-date-picker" appearance="outline">
          <input type="hidden" placeholder="Month" [matDatepicker]="picker2" [value]="date2"/>
          <input matInput type="text" [value]="pickerDisplayValue(date2!)"/>
          <mat-datepicker-toggle matIconSuffix [for]="picker2">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker2 [startView]="getPickerStartView"
                          (yearSelected)="selectYear($event, picker2, 2)"
                          (monthSelected)="selectMonth($event, picker2, 2)"></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="dateFilterType == 'custom-range'" fxFlexAlign="end">
        <app-custom-date-filter [customDateRangePresets]="customDateRangePresets"
                                [initialPreset]="initialPreset"
                                [compactPresets]="true"
                                [showBorder]="false"
                                (dateRange)="getDateRange($event)"></app-custom-date-filter>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="7px"
         class="btn-cursor" (click)="openInfoDialog()">
      <span class="link-default btn-cursor" style="font-size: 9px !important;">Not seeing all the data?</span>
    </div>

    <div fxLayout="row">
      <div class="dashboard-canvas-container" *ngIf="chart1" [fxFlex]="chart2 ? 50 : 100">
        <canvas class="dashboard-canvas" baseChart *ngIf="!chart1.loading; else canvasLoadingSpinner"
                [height]="(screenSize.mobileView$ | async) ? 200 : 350"
                [data]="chart1.chartData"
                [options]="chart1.config"
                [type]="chart1.chartType">
        </canvas>
      </div>
      <div class="dashboard-canvas-container" *ngIf="chart2" [fxFlex]="50">
        <canvas class="dashboard-canvas" baseChart *ngIf="!chart2.loading; else canvasLoadingSpinner"
                [height]="(screenSize.mobileView$ | async) ? 200 : 350"
                [data]="chart2.chartData"
                [options]="chart2.config"
                [type]="chart2.chartType">
        </canvas>
      </div>
    </div>

    <div fxLayout="row">
      <div class="p-2" *ngIf="chart1" [fxFlex]="chart2 ? 50 : 100">
          <span class="body-1-default">{{chart1.chartName}}:
            <span class="body-1-regular-2">{{chart1.chartDescription}}</span>
          </span>
      </div>

      <div class="p-2" *ngIf="chart2" [fxFlex]="50">
          <span class="body-1-default">{{chart2.chartName}}:
            <span class="body-1-regular-2">{{chart2.chartDescription}}</span>
          </span>
      </div>
    </div>

  </div>

  <ng-template #canvasLoadingSpinner>
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 350px">
      <mat-spinner color="primary" diameter="30"></mat-spinner>
    </div>
  </ng-template>
</div>


