<form class="full-width info-card-grey form-info-card px-0" [formGroup]="userRegistrationForm" fxLayout="column"
    fxLayoutAlign="center center" fxLayoutGap="30px">

  <mat-error class="body-1-error form-error mb-0 mt-3" *ngIf="errMessage">{{ errMessage }}</mat-error>
  <mat-error class="body-1-error mb-0 mt-3" *ngIf="userRegistrationForm.get('email')!.hasError('email-exists')">A user with this email already exists</mat-error>

  <app-application-form-row fieldLabel="Name" errorMessage="Name is required" fxFlexOffset="30px"
        [showError]="hasError('firstname')">
        <input matInput formControlName="firstname" placeholder="Enter your answer" required="true">
    </app-application-form-row>

    <app-application-form-row fieldLabel="Last name" errorMessage="Last name is required"
        [showError]="hasError('lastname')">
        <input matInput formControlName="lastname" placeholder="Input text" />
    </app-application-form-row>

    <app-application-form-row fieldLabel="Email address" errorMessage="Email is required"
        [showError]="hasError('email')">
        <input matInput formControlName="email" placeholder="Input text" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$" type="email" />
        <mat-error class="link-error" *ngIf="hasError('email','pattern')">Email format required, e.g. sample-email&#64;domain.com</mat-error>
    </app-application-form-row>

    <app-application-form-row fieldLabel="Create password" errorMessage="Password is required"
        [showError]="hasError('secret')">
        <input matInput #password [type]="hide ? 'password' : 'text'" formControlName="secret" placeholder="Input text" matSuffix/>
        <mat-icon (click)="hide = !hide" *ngIf="hide" class="btn-cursor icon-password-suffix" matSuffix>visibility</mat-icon>
        <mat-icon (click)="hide = !hide" *ngIf="!hide" class="btn-cursor icon-password-suffix" matSuffix>visibility_off</mat-icon>
        <mat-error class="link-error" *ngIf="hasError('secret', 'not-valid')">
            Passwords need to be at least 8 characters long, contain at lest one upper and lower case letter, a number
            and one special character (!&#64;#$%)
        </mat-error>
    </app-application-form-row>

    <app-application-form-row fieldLabel="Confirm password" errorMessage="Confirm password is required"
        [showError]="hasError('confirmPassword')">
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword" placeholder="Input text" />
        <mat-icon (click)="hide = !hide" *ngIf="hide" class="btn-cursor icon-password-suffix" matIconSuffix>visibility</mat-icon>
        <mat-icon (click)="hide = !hide" *ngIf="!hide" class="btn-cursor icon-password-suffix" matIconSuffix>visibility_off</mat-icon>
        <mat-error class="link-error" *ngIf="hasError('confirmPassword', 'no-match')">Passwords do not match</mat-error>
    </app-application-form-row>

    <app-application-form-row-label [lastRow]="true">
        <div class="subtitle-regular">
            Already have an account? <span class="heading-primary-1 btn-cursor" (click)="gotoLogin()">Log in</span>
          </div>
    </app-application-form-row-label>
</form>
