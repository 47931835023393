export class DailyTransactionsSummary {
  leaseUuid!: string;
  retailerId!: number;
  retailerCompanyName!: string;
  transactionDate!: string;
  netBalancePayableRetailerInclRounded!: number;
  feesTotalIncl!: number;
  turnoverRentalFeeIncl!: number;
  transactionFeeIncl!: number;
  saleAmountIncl!: number;
  transactionCount!: number;
  totalFloorSpace!: number;
}

export interface TransactionSummary {
  uuid: string,
  leaseUuid: string,
  retailerId: number,
  retailerCompanyName: string,
  productName: string,
  paymentMethods: string,
  quantity: number,
  districtUuid: string,
  paymentBatchUuid: string,
  transactionDate: string,
  saleStatus: string,
  processingStatus: string,
  pointOfSaleStatus: string,
  saleType: string,
  returnFor: string,
  returnUuids: string[],
  transactionSource: string,
  outletId: string,
  customerId: string,
  transactionInvoiceNumber: string,
  currency: string,
  saleAmount: number,
  saleAmountTax: number,
  saleAmountIncl: number,

  transactionFee: number,
  transactionFeeTax: number,
  transactionFeeIncl: number,

  turnoverRentalFee: number,
  turnoverRentalFeeTax: number,
  turnoverRentalFeeIncl: number,

  feesTotal: number,
  feesTotalTax: number,
  feesTotalIncl: number,

  paymentsCollectedIncl: number,
  paymentsCollectedExcl: number,
  paymentsExternalIncl: number,
  paymentsExternalExcl: number,

  netBalancePayableRetailer: number,
  netBalancePayableRetailerIncl: number,
  netBalancePayableRetailerInclRounded: number
}

export interface RetailerPayoutSummary {
  leaseUuid: string,
  paymentBatchUuid: string,
  districtUuid: string,
  processedDate: string,
  paymentDate: string,
  processStatus: string,
  retailerId: number,
  retailerCompanyName: string,
  rollupNetBalancePayable: number,
  rollupNetBalancePayableRounded: number,
  transactionCount: number,
  batchTransactionsPerRetailerRollup: string,
  saleAmountInclTotal: number,
  transactionFeeInclTotal: number,
  turnoverRentalFeeInclTotal: number,
  paymentsCollectedInclTotal: number,
  paymentsExternalInclTotal: number
}

export interface GenericChart {
  chartSeriesName: string,
  chartData: GenericChartData[],
  chartInfo: GenericChartInfo
}

export interface GenericChartData {
  data: number,
  dateLabel: string;
  intLabel: number;
}

export interface GenericChartInfo {
  saleAmountIncl: number,
  averageBasketSize: number,
  averageTradingDensity: number,
  transactionCount: number,
  startDate: string,
  endDate: string
}

export enum SaleStatus {
  SAVED = 'SAVED',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  VOID = 'VOID',
  UNKNOWN = 'UNKNOWN'
}

export enum TransactionStatus {
  ON_HOLD = 'ON_HOLD',

  NEW = 'NEW',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  ERROR_PAYMENT_CONFIG = 'ERROR_PAYMENT_CONFIG',

  STAGED_IN_PAYMENT_BATCH = 'STAGED_IN_PAYMENT_BATCH',
  STAGED_IN_PAYMENT_ERROR = 'STAGED_IN_PAYMENT_ERROR',

  SUBMITTED_FOR_PAYMENT = 'SUBMITTED_FOR_PAYMENT',
  PAID = 'PAID',
  ERROR_PAYMENT_FAILED = 'ERROR_PAYMENT_FAILED',

  PENDING_REFUND_RECOVERY = 'PENDING_REFUND_RECOVERY',
  STAGED_FOR_RECOVERY = 'STAGED_FOR_RECOVERY',
  REFUND_ERROR_NO_PARENT = 'REFUND_ERROR_NO_PARENT',

  SUBMITTED_FOR_RECOVERY = 'SUBMITTED_FOR_RECOVERY',
  SUBMITTED_FOR_RECOVERY_ERROR = 'SUBMITTED_FOR_RECOVERY_ERROR',

  RECOVERED = 'RECOVERED',
  RECOVERY_ERROR = 'RECOVERY_ERROR',
  ERROR_INSUFFICIENT_MONEY_COLLECTED = 'ERROR_INSUFFICIENT_MONEY_COLLECTED',

  VOID = 'VOID',
  ERROR_UNKNOWN_SALE_STATUS = 'ERROR_UNKNOWN_SALE_STATUS'
}

export enum POSSaleStatus {
  SAVED = 'SAVED',
  CLOSED = 'CLOSED',
  ONACCOUNT = 'ONACCOUNT',
  ONACCOUNT_CLOSED = 'ONACCOUNT_CLOSED',
  LAYBY = 'LAYBY',
  LAYBY_CLOSED = 'LAYBY_CLOSED',
  AWAITING_DISPATCH = 'AWAITING_DISPATCH',
  AWAITING_PICKUP = 'AWAITING_PICKUP',
  DISPATCHED_CLOSED = 'DISPATCHED_CLOSED',
  PICKED_UP_CLOSED = 'PICKED_UP_CLOSED',
  VOIDED = 'VOIDED',
  UNKNOWN = 'UNKNOWN'
}

export enum BatchStatus {
  UNSUBMITTED = 'UNSUBMITTED',
  SUBMITTED = 'SUBMITTED',

  SUBMIT_FAILED = 'SUBMIT_FAILED',

  PROCESSED = 'PROCESSED',
  PROCESS_ERROR = 'PROCESS_ERROR',
}

