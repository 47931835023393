import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {concatMap, from, lastValueFrom, Observable, tap} from 'rxjs';
import {catchError} from "rxjs/operators";
import {LandlordsService} from 'src/app/core/services/landlords.service';
import {FilterItems} from '../../../../core/model/district.model';
import {LoadingService} from "../../../../core/services/loading.service";
import {OfferingApplicationService} from '../../../../core/services/offering-application.service';
import {CurrentContextService} from '../../../../core/services/security/current-context.service';
import {MapsAddressUtilService} from '../../../../shared/services/maps-address-util.service';
import {
  Category,
  ControlType,
  CurationForm,
  FormFileValue,
  FormQuestionFilter,
  FormState,
  Option,
  Question,
  QuestionGroup,
  QuestionTemplate,
  RetailerCurationForm
} from '../offering-application.model';

@Component({
  selector: 'app-offering-application-form',
  templateUrl: './offering-application-form.component.html',
  styleUrls: ['./offering-application-form.component.scss']
})
export class OfferingApplicationFormComponent {
  @Input() returnUrl?: string | null;
  @Input() lastUpdated!: Date;
  @Output() lastUpdatedChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() assessmentStatus: EventEmitter<string> = new EventEmitter<string>;
  @Output() submitError: EventEmitter<string> = new EventEmitter<string>();

  filterItems: {
    services: FilterItems[],
    products: FilterItems[],
    lease_types: FilterItems[]
  } = {
    services: [],
    products: [],
    lease_types: []
  };

  assessment?: RetailerCurationForm;
  payload?: CurationForm;

  forms?: FormGroup[];

  place: any;
  geometry: any;

  loading = false;
  pageUntouched = true;

  formChecked = false;
  submittingForm = false;
  applicationOfferingUuid: string | null = null;
  correlationUuid: string | null = null;

  groupUuids: string[] = [];

  newFiles: Map<string, { qUuid: string, file: File, fileCode: string }[]> = new Map<string, { qUuid: string, file: File; fileCode: string}[]>();

  savedFiles: { qUuid: string, fileUrl: string, blob: ArrayBuffer, fileType: string, fileCode: string, fileName: string }[] = [];
  failedUpload: {key: string, error: string}[] = [];

  EXCLUDED_QUESTION_TYPES: string[] = [
    ControlType.HEADING,
    ControlType.HEADING_TEXT,
    ControlType.INFO_FILE
  ]

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public offeringApplicationService: OfferingApplicationService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private contextService: CurrentContextService,
              public mapsAddressUtils: MapsAddressUtilService,
              public loader: LoadingService,
              private landlordService: LandlordsService) {
    this.matIconRegistry.addSvgIcon('icon-soko-plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/plus-circle.svg'));
    this.matIconRegistry.addSvgIcon('icon-shopping-bag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('..//assets/icons/curate/shopping-bag.svg'));
  }

  ngOnInit(): void {
    this.offeringApplicationService.setFormState(this.activatedRoute.snapshot.queryParamMap.get('formState') as FormState);
    this.offeringApplicationService.setProcessStep(0);

    if (!this.isFormPublic) {
      this.offeringApplicationService.setProcessStep(2);
    }

    this.filterItems = {services: [], products: [], lease_types: []};
    this.returnUrl = this.activatedRoute.snapshot.paramMap.get('returnUrl') ? this.activatedRoute.snapshot.paramMap.get('returnUrl') : null;
    this.loading = true;

    if (this.offeringApplicationService.getFormState != FormState.IN_APP) {
      let locationCode = this.activatedRoute.snapshot.paramMap.get('locationCode') as string || this.activatedRoute.snapshot.queryParamMap.get('locationCode') as string;
      let offeringCode = this.activatedRoute.snapshot.paramMap.get('offeringCode') as string;
      this.loadOfferingByCode(locationCode, offeringCode).then(() => {
        this.loadApplication();
      });
    } else {
      this.applicationOfferingUuid = this.contextService.getCurrentOffering()!.offeringUuid
      this.loadApplication();
    }
  }

  async loadOfferingByCode(locationCode: string, offeringCode: string): Promise<void> {
    try {
      const res = await lastValueFrom(this.landlordService.getOfferingByCode(locationCode, offeringCode));
      this.applicationOfferingUuid = res.offeringUuid;
    } catch (error) {
      console.log(error);
    }
  }

  loadApplication() {
    if (this.isFormPublic) {
      this.loadOfferingApplication();
    } else {
      this.loadRetailerApplication();
    }
  }

  loadRetailerApplication() {
    lastValueFrom(this.offeringApplicationService.getRetailerApplicationStatusForOffering(this.applicationOfferingUuid!))
      .then((status: string) => {
        this.offeringApplicationService.toggleApplicationNew(status.toLowerCase() === 'new');
        this.assessmentStatus.emit(status);
        if (!this.offeringApplicationService.isApplicationNew && this.offeringApplicationService.isReadonly) {
          lastValueFrom(this.offeringApplicationService.getCurrentByRetailerIdAndOffering(this.applicationOfferingUuid))
            .then((res: RetailerCurationForm) => {
                this.assessment = res;
                this.payload = {...res.form};
                this.lastUpdated = res.updateDate!;
                this.lastUpdatedChange.emit(this.lastUpdated);
                this.setInitialRepeatedValue();
                this.setGroupUuids();
                this.getSavedImages();
                this.loading = false;
              }, err => {
                console.log(err);
                this.loading = false;
              }
            );
        } else {
          this.getANewTemplate(this.applicationOfferingUuid);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  loadOfferingApplication() {
    this.offeringApplicationService.toggleApplicationNew(true);
    lastValueFrom(this.offeringApplicationService.getNewPublicAssessment(this.applicationOfferingUuid!))
      .then((res: RetailerCurationForm) => {
          this.assessment = res;
          this.payload = {...res.form};
          this.setInitialRepeatedValue();
          this.setGroupUuids();
          this.getSavedImages();
          this.loading = false;
          this.offeringApplicationService.toggleFormReadOnly(false);
        }, err => {
          console.log(err);
          this.loading = false;
        }
      );
  }

  saveCurationForm() {
    this.checkApplicationFileValues();
    if (this.failedUpload.length > 0) {
      this.submitError.emit("Could not submit form!")
      return false;
    }

    this.loading = true;
    this.submittingForm = true;
    this.pageUntouched = false;
    const assessment = {...this.assessment};
    assessment.form = this.payload;
    assessment.retailerId = this.contextService.getCurrentRetailer().id;
    let savePromise: Promise<RetailerCurationForm>;
    if (this.applicationOfferingUuid != null) {
      savePromise = lastValueFrom(this.offeringApplicationService.saveAssessment(assessment, this.applicationOfferingUuid, this.correlationUuid));
    } else {
      savePromise = lastValueFrom(this.offeringApplicationService.saveAssessment(assessment, null, this.correlationUuid));
    }

    return savePromise
      .then((updatedApplicationForm: RetailerCurationForm) => {

          if (this.offeringApplicationService.isApplicationNew) {
            this.contextService.setRetailerNew(false);
          }
          this.loading = false;
          this.submittingForm = false;
          this.lastUpdatedChange.emit(updatedApplicationForm.updateDate);
          this.lastUpdated = updatedApplicationForm.updateDate;

          if (this.onApplicationApplicationsPage()) {
            this.cancelCurrentProcess();
          }
          return Promise.resolve(true);
        },
        err => {
          this.loading = false;
          this.submittingForm = false;
          this.submitError.emit('Could not submit retailer assessment');
          console.log(err);
          return Promise.resolve(false);
        }
      );
  }

  sortItemsByDisplayOrder(items: Category[] | QuestionGroup[] | Question[]): any {
    return items.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  public async submitRetailerOfferingApplication() {
    if (this.checkFormValidity(true)) {
      this.correlationUuid = await this.setCorrelationUuid();
      if (this.newFiles.size > 0) {
        const res = await lastValueFrom(this.uploadNewFiles());
        if (res)
          return this.saveCurationForm();
        this.submitError.emit('Could not upload all files!');
        return false;
      } else {
        return this.saveCurationForm();
      }
    }
    return Promise.resolve(false);
  }

  private getANewTemplate(offeringUuid?: string | null): void {
    lastValueFrom(this.offeringApplicationService.getNewAssessment(offeringUuid))
      .then((res: RetailerCurationForm) => {
          this.assessment = res;
          this.payload = {...res.form};
          this.setInitialRepeatedValue();
          this.setGroupUuids();
          this.getSavedImages();
          this.loading = false;
          this.offeringApplicationService.toggleFormReadOnly(false);
          }, err => {
          console.log(err);
          this.loading = false;
        }
      );
  }

  getQuestionTemplate(question: Question): QuestionTemplate {
    return question.questionTemplate;
  }

  getSelectConfig(qt: QuestionTemplate): boolean {
    return qt.controlPayload.selectType !== 'SINGLE';
  }

  checkFormValidity(check: boolean): boolean {
    if (check) {
      this.formChecked = true;
    }
    let valid = true;

    if (this.payload) {
      this.payload!.categories.forEach(category => {
        category.questionGroups.forEach(group => {
          const invalidQuestions = group.questions.filter(f => !this.EXCLUDED_QUESTION_TYPES.includes(f.questionTemplate.controlType) && f.required && f.value === null);
          const invalidFileQuestions = group.questions.filter(f => f.questionTemplate.controlType == ControlType.FILE && f.required)
            .filter(f => this.getQuestionFileValues(f).length < this.getQuestionFiles(f).length);
          valid = invalidQuestions.length === 0 && valid && invalidFileQuestions.length === 0;
        });
      });
    }

    return valid && this.failedUpload.length == 0;
  }

  changeValue(event: any,
              i: number,
              j: number,
              k: number,
              control: any,
              qt ?: QuestionTemplate,
              code?: string): any {
    let value;
    switch (control) {
      case 'DATE':
        value = this.formatStringToDate(event.target.value);
        break;
      case 'RANGE':
        value = event.valueCode;
        break;
      case 'LOCATION':
        if (this.place && this.geometry) {
          value = this.mapsAddressUtils.parseAddressData(this.geometry, this.place, 'PROSPECTIVE');
        }
        break;
      case 'FILE':
        value = JSON.stringify(this.fileChangeEvent(event, qt!, code!, this.payload!.categories[i].questionGroups[j].questions[k]));
        break;
    }
    this.payload!.categories[i].questionGroups[j].questions[k].value = value;
  }

  changeRepeatedValue(event: any,
                      i: number,
                      j: number,
                      r: number,
                      k: number,
                      control: any,
                      qt ?: QuestionTemplate,
                      code?: string): any {
    let value;
    switch (control) {
      case 'DATE':
        value = this.formatStringToDate(event.target.value);
        break;
      case 'RANGE':
        value = event.valueCode;
        break;
      case 'LOCATION':
        if (this.place && this.geometry) {
          value = this.mapsAddressUtils.parseAddressData(this.geometry, this.place, 'PROSPECTIVE');
        }
        break;
      case 'FILE':
        value = JSON.stringify(this.fileChangeEvent(event, qt!, code!, this.payload!.categories[i].questionGroups[j].repeatedValues[r][k]));
        break;
    }
    this.payload!.categories[i].questionGroups[j].repeatedValues[r][k].value = value;
  }

  setInitialRepeatedValue() {
    this.payload!.categories.forEach((cat, i) => {
      cat.questionGroups.forEach((group, j) => {
        if (group.repeatedInput && group.repeatedValues.length == 0) {
          this.addItem(group);
        }
      });
    });
  }

  getSavedImages() {
    this.savedFiles = [];

    this.payload?.categories.forEach(cat => {
      cat.questionGroups.forEach(group => {
        group.questions.forEach(q => {
          if ((q.questionTemplate.controlType == ControlType.FILE || q.questionTemplate.controlType == ControlType.INFO_FILE) && q.value && q.value != '') {
            this.getFormFiles(q.value, q.questionTemplate.uuid, this.generateQuestionFilter(cat.uuid, group.uuid, q.uuid, null));
          }
        })
      })
    });
  }

  onAddressAutocompleteSelected(event: any): void {
    this.place = event;
  }

  onAddressLocationSelected(event: any): void {
    this.geometry = event;
  }

  formatStringToDate(dateString: string | number | Date): any {
    if (dateString) {
      return new Date(dateString).toISOString();
    }
    return null;
  }

  fileChangeEvent(event: any, qt: QuestionTemplate, fileCode: string, q: Question): FormFileValue[] {
    const currentValue = this.getQuestionFileValues(q) && this.getQuestionFileValues(q).length > 0 ? this.getQuestionFileValues(q) : [];
    const currentIndex = currentValue.findIndex(f => f.fileCode == fileCode);

    const maxFileSize = qt.controlPayload.maxSizeMb! * 1024 * 1024;

    if (event.target.files.length > 0) {
      const fileToUpload: File = event.target.files.item(0);

      if (!this.newFiles.has(qt.uuid)) {
        this.newFiles.set(qt.uuid, [{ qUuid: qt.uuid, file: fileToUpload, fileCode: fileCode }])
      } else {
        const questionFiles = this.newFiles.get(qt.uuid)!;

        const questionIndex = questionFiles.findIndex(f => f.fileCode == fileCode);
        if (questionIndex > -1) {
          questionFiles[questionIndex].file = fileToUpload;
        } else {
          questionFiles.push({ qUuid: qt.uuid, file: fileToUpload, fileCode: fileCode });
        }
      }

      if (currentIndex > -1) {
        currentValue[currentIndex] = { fileName: fileToUpload.name, fileUuid: '', fileType: fileToUpload.type, fileCode: fileCode};
      } else {
        currentValue.push({ fileName: fileToUpload.name, fileUuid: '', fileType: fileToUpload.type, fileCode: fileCode});
      }

      // reset failed upload when changing file
      this.failedUpload = this.failedUpload.filter(f => f.key != this.fileCompositeKey(qt.uuid, fileCode));

      // if exceeds max file size
      if (fileToUpload.size > maxFileSize) {
        this.failedUpload.push({key: this.fileCompositeKey(qt.uuid, fileCode), error: `File size cannot exceed ${qt.controlPayload.maxSizeMb}MB.`})
      }
    }
    return currentValue;
  }

  getQuestionFileValues(q: Question): FormFileValue[] {
    if (q.value != null) return JSON.parse(q.value);
    return [];
  }

  getFileInfo(q: Question, code: string): FormFileValue | null {
    const files = this.getQuestionFileValues(q);
    if (files.length > 0) {
      return files.filter(f => f.fileCode == code)[0];
    }
    return null;
  }

  uploadNewFiles() {
    this.failedUpload = [];
    const newFileRequests: {[key: string]: Observable<string>} = {};

    this.newFiles.forEach((value, key) => {
      value.forEach(ea => {
        const compositeKey: string = this.fileCompositeKey(key, ea.fileCode)
        newFileRequests[compositeKey] = this.offeringApplicationService.uploadFormFile(ea.file, this.correlationUuid)
          .pipe(catchError((error) => {
            console.log(`Error uploading file ${compositeKey}:`, error);
            this.failedUpload.push({key: compositeKey, error: "Error uploading file. The file could be too large or in an incorrect format."});
            return "";
          }));
      });
    });


    return from(Object.entries(newFileRequests)) // Convert map entries to observable
      .pipe(
        concatMap(([key, uploadObservable]) => // Iterate over each key-value pair
          uploadObservable.pipe(
            tap(uploadedFileUuid => {
              this.payload?.categories.forEach(cat => {
                cat.questionGroups.forEach(group => {
                  group.questions.forEach(q => {
                    if (q.questionTemplate.controlType == ControlType.FILE) {
                      const currentValue: FormFileValue[] = JSON.parse(q.value);
                      const [qUuid, fileCode] = key.split('_|_');
                      if (qUuid == q.questionTemplate.uuid) {
                        const updateValueIndex = currentValue.findIndex(f => f.fileCode == fileCode);
                        if (updateValueIndex > -1) {
                          currentValue[updateValueIndex].fileUuid = uploadedFileUuid;
                          q.value = JSON.stringify(currentValue);
                        }
                      }
                    }
                  })
                });
              });
              return Promise.resolve(true);
            }),
            catchError(error => {
              console.log(`Error uploading file ${key}:`, error);
              this.failedUpload.push({
                key,
                error: "Error uploading file. The file could be too large or in an incorrect format."
              });
              return Promise.resolve(false);
            })
          )
        ));
  }

  getFormFiles(fileValue: string, qUuid: string, filter: FormQuestionFilter) {
    const docObjects: FormFileValue[] = JSON.parse(fileValue);
    docObjects.forEach(docObject => {
      filter.fileCode = docObject.fileCode;
      this.offeringApplicationService.downloadFormFile(docObject.fileUuid, filter).subscribe({
        next: (value) => {
          const type = ('application/' + docObject.fileType).toString();
          const file = new Blob([value], { type });
          const fileURL = URL.createObjectURL(file);

          this.savedFiles.push({qUuid, fileUrl: fileURL, blob: value, fileType: docObject.fileType, fileCode: docObject.fileCode, fileName: docObject.fileName});
        },
        error: err => console.log(err)
      })
    })
  }

  downloadFormFile(qUuid: string, fileCode: string) {
    if (this.offeringApplicationService.isReadonly) {
      const savedFile = this.savedFiles.filter(f => f.qUuid == qUuid && f.fileCode == fileCode);

      if (savedFile.length > 0) {
        const anchor = document.createElement('a');
        anchor.href = savedFile[0].fileUrl;
        anchor.download = savedFile[0].fileName;
        anchor.click();
      }
    }
  }

  downloadInfoFile(qUuid: string, fileCode: string) {
    const savedFile = this.savedFiles.filter(f => f.qUuid == qUuid && f.fileCode == fileCode);

    if (savedFile.length > 0) {
      const anchor = document.createElement('a');
      anchor.href = savedFile[0].fileUrl;
      anchor.download = savedFile[0].fileName;
      anchor.click();
    }
  }

  cancelCurrentProcess(): void {
    this.offeringApplicationService.toggleFormReadOnly(true);
    if (this.router.url.includes('user-profile/curation')) {
      this.contextService.clearCurrentOffering();
      this.router.navigate([`/${this.contextService.getCurrentLocationCode()}/home`])
        .catch((err: string) => {
          console.log(err);
        });
    }
  }

  public addItem(questionGroup: QuestionGroup): void {
    const questions: Question[] = [];
    questionGroup.questions.forEach(q => {
      questions.push({...q});
    });
    questionGroup.repeatedValues.push(questions);
  }

  setGroupUuids() {
    this.groupUuids = [];
    this.payload?.categories.forEach(cat => {
      const uuids = cat.questionGroups.map(m => m.uuid);
      this.groupUuids.push(...uuids);
    })
  }

  getIndex(groupUuid: string): number {
    return this.groupUuids.indexOf(groupUuid) + 1;
  }

  get isFormPublic(): boolean {
    return this.offeringApplicationService.getFormState == FormState.REFERRER
      || this.offeringApplicationService.getFormState == FormState.EMBED;
  }

  onApplicationApplicationsPage(): boolean {
    return (this.router.url.includes('/forms/applications'));
  }

  getImageUrl(question: Question, fileCode: string): string | SafeStyle {
    if (this.isFileTypeImage(question, fileCode)) {
      if (this.newFiles.has(question.questionTemplate.uuid)) {
        const newFile = this.newFiles.get(question.questionTemplate.uuid)!.filter(f => f.fileCode == fileCode);
        if (newFile.length > 0) {
          return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(newFile[0].file));
        }
      }

      const savedFile =  this.savedFiles.filter(f => f.qUuid == question.questionTemplate.uuid && f.fileCode == fileCode);

      if (savedFile.length > 0) {
        const base64 = this._arrayBufferToBase64(savedFile[0].blob);
        return this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${base64}`);
      }
    }
    return '';
  }

  isFileTypeImage(question: Question, fileCode: string): boolean {
    if (this.newFiles.has(question.questionTemplate.uuid)) {
      const newFile = this.newFiles.get(question.questionTemplate.uuid)!.filter(f => f.fileCode == fileCode);
      if (newFile.length > 0 && newFile[0].file) {
        return newFile[0].file.type.includes('image/')
      }
    }

    const savedFile =  this.savedFiles.filter(f => f.qUuid == question.questionTemplate.uuid && f.fileCode == fileCode);
    return savedFile.length > 0 && savedFile[0].fileType.includes('image/');
  }

  removeImage(question: Question, $event: MouseEvent, fileCode: string, input: HTMLInputElement) {
    if (!this.offeringApplicationService.isReadonly) {
      $event.stopPropagation();
      $event.preventDefault();
      const currentValue = this.getQuestionFileValues(question);
      question.value = JSON.stringify(currentValue.filter(f => f.fileCode != fileCode));
      // reset failed upload when changing file
      this.failedUpload = this.failedUpload.filter(f => f.key != this.fileCompositeKey(question.questionTemplate.uuid, fileCode));
    }
  }

  getQuestionOptions(question: Question) {
    if (this.offeringApplicationService.isReadonly) {
      if (question.value == null || question.value.length == 0) return [];
      return question.questionTemplate.controlPayload.options!.filter(f => question.value.includes(f.valueCode!));
    }
    return question.questionTemplate.controlPayload.options;
  }

  getQuestionFiles(question: Question): Option[] {
    if (this.offeringApplicationService.isReadonly) {
      if (question.value == null || question.value.length == 0) return [];
      return question.questionTemplate.controlPayload.files!.filter(f => question.value.includes(f.valueCode!));
    }
    return question.questionTemplate.controlPayload.files ? question.questionTemplate.controlPayload.files : [];
  }

  _arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  checkFileValidity(q: Question, fileCode: string, input: HTMLInputElement) {
    const filePresent = this.getFileInfo(q, fileCode) != null;
    const inputTouched = input.checkValidity();
    return !inputTouched || filePresent;
  }

  checkApplicationFileValues() {
    this.assessment?.form.categories.forEach(cat => {
      cat.questionGroups.forEach(qg => {
        const fileQuestions = qg.questions.filter(q => q.questionTemplate.controlType == ControlType.FILE);
        fileQuestions.forEach(fq => {
          const fileValues = this.getQuestionFileValues(fq);
          fileValues.forEach(fv => {
            if (!fv.fileUuid) {
              this.failedUpload.push({
              key: this.fileCompositeKey(fq.questionTemplate.uuid, fv.fileCode),
              error: 'Uploaded file is invalid. Please try re-uploading this file.'
              })
            }
          })
        })
      })
    })
  }

  fileUploadErrorMessage(q: Question, fileCode: string): string | null {
    const fileFailedUpload = this.failedUpload
      .findIndex(f => f.key == this.fileCompositeKey(q.questionTemplate.uuid, fileCode));
    return fileFailedUpload < 0 ? null : this.failedUpload[fileFailedUpload].error;
  }

  generateQuestionFilter(catId: string, qgId: string, qId: string, fileCode: string | null) {
    const filter: FormQuestionFilter = {
      assessmentId: this.assessment!.retailerCurationAssessmentId,
      formCode: this.payload!.formCode!,
      categoryUuid: catId,
      questionGroupUuid: qgId,
      questionUuid: qId,
      fileCode: fileCode
    }
    return filter;
  }

  fileCompositeKey(qtUuid: string, fileCode: string) {
    return `${qtUuid}_|_${fileCode}`;
  }

  private async setCorrelationUuid(): Promise<string | null> {
    const assessment = {...this.assessment};
    assessment.form = this.payload;
    assessment.retailerId = this.contextService.getCurrentRetailer().id;
    try {
      return await lastValueFrom(this.offeringApplicationService.preSave(assessment, this.applicationOfferingUuid));
    } catch (err) {
      console.error(err);
      return Promise.resolve(null);
    }
  }

  protected readonly FormState = FormState;
  protected readonly ControlType = ControlType;
}
