<div class="full-width custom-date-filter-wrapper" fxLayoutGap="10px" fxLayout="column" fxLayout.gt-xs="row"
     fxLayoutAlign="start center">
  <div *ngIf="presetsShowing && !compactPresets">
    <mat-button-toggle-group [ngClass]="showBorder ? 'custom-date-button-toggle' : 'custom-borderless-button-toggle'"
                             [(value)]="dateOption" (change)="broadcastDateRange($event)"
                             [disabled]="loader.loading$ | async">
      <ng-container *ngFor="let customRange of customDateRangePresets">
        <mat-button-toggle value="{{customRange.presetId}}">
          {{customRange.getReadableFormat()}}
        </mat-button-toggle>
      </ng-container>
      <mat-button-toggle [value]="'custom'" fxHide></mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="presetsShowing && compactPresets">
    <mat-form-field class="custom-select-field" appearance="outline" style="width: 190px;">
      <mat-select class="custom-select-field-section"
                  [(value)]="dateOption" (selectionChange)="broadcastDateRange($event)" [disabled]="loader.loading$ | async">
        <mat-option class="custom-select-field-option" *ngFor="let customRange of customDateRangePresets" [value]="customRange.presetId">
          {{customRange.getReadableFormat()}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-form-field [ngClass]="dateOption === 'custom' ? 'custom-date-picker-active' : 'custom-date-picker'"
                  appearance="outline"
                  *ngIf="datePickerShowing" floatLabel="always">
    <mat-label *ngIf="customLabel">{{customLabel}}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" (click)="selectCustomDate(false)" [min]="minDate" [max]="maxDate" [disabled]="loader.loading$ | async">
      <input matStartDate formControlName="start" placeholder="Custom"/>
      <input matEndDate formControlName="end" (dateChange)="selectCustomDate(true)" />
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>
  <div *ngIf="showClearButton">
    <button class="button-action-cancel" mat-flat-button (click)="clearFilters()" [disabled]="loader.loading$ | async">
      <div class="button-text">Clear</div>
    </button>
  </div>
</div>



