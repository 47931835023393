<div class="page-padding page-content full-screen-height-page-default" fxLayout="column">

  <div>
    <div fxLayout="row">
      <span class="subtitle-default">Pay-out Summary</span>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <span class="heading-default">Pay-out summary for retailers</span>
      <button
        [disabled]="!!(loader.loading$ | async)"
        [matMenuTriggerFor]="mainOptionsMenu"
        mat-icon-button
        matTooltip="Table options"
      >
        <mat-icon class="crud-table-main-toolbar-icon">more_vert</mat-icon>
      </button>
      <mat-menu #mainOptionsMenu="matMenu">
        <!-- Export to CSV -->
        <button (click)="exportToCsv()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to CSV</span>
        </button>

        <!-- Export to Excel -->
        <button (click)="exportToExcel()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to Excel</span>
        </button>

        <!-- Export to PDF -->
        <button (click)="exportToPdf()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to PDF</span>
        </button>

      </mat-menu>
    </div>
    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">

          <mat-form-field appearance="outline" class="custom-select-field" floatLabel="always">
          <mat-label>Payout Status</mat-label>
            <mat-select class="custom-select-field-value" formControlName="payoutStatus" multiple placeholder="Select">
            <mat-option class="custom-select-field-option" *ngFor="let status of batchStatuses"
                        [value]="status">{{utils.displayStatus(status)}}</mat-option>
          </mat-select>
        </mat-form-field>
          <button (click)="filterPayoutSummary()" [disabled]="!!(loader.loading$ | async)" color="primary"
                  mat-flat-button>
            Filter
          </button>
        </div>


        <app-custom-date-filter [customDateRangePresets]="dateFilters" [showBorder]="false"
                                (dateRange)="getDateRange($event)"
                                [showClearButton]="true"
                                [initialPreset]="'1'"></app-custom-date-filter>

      </div>
    </form>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px" fxFlex="60">
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Transaction </th>
      </ng-container>

      <ng-container matColumnDef="paymentBatchUuid">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.paymentBatchUuid"
            *matCellDef="let element"> {{utils.displayUuid(element.paymentBatchUuid)}} </td>
      </ng-container>

      <ng-container matColumnDef="processedDate">
        <th mat-header-cell *matHeaderCellDef> Processed Date </th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ dateUtils.displayDateWithTime(element.processedDate) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="earliestTransactionDate">
        <th mat-header-cell *matHeaderCellDef> Includes Sales From </th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ dateUtils.displayShortDate(element.earliestTransactionDate) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="latestTransactionDate">
        <th mat-header-cell *matHeaderCellDef> Includes Sales To </th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ dateUtils.displayShortDate(element.latestTransactionDate) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="saleAmountInclTotal">
        <th mat-header-cell *matHeaderCellDef> Sale Amount </th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.saleAmountInclTotal) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentsExternalInclTotal">
        <th *matHeaderCellDef mat-header-cell> External Payments</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell>{{ utils.displayRandAmountWithDecimal(element.paymentsExternalInclTotal) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentsCollectedInclTotal">
        <th *matHeaderCellDef mat-header-cell> Internal Payments</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell>{{ utils.displayRandAmountWithDecimal(element.paymentsCollectedInclTotal) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transactionFeeInclTotal">
        <th mat-header-cell *matHeaderCellDef> Transaction Fee </th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.transactionFeeInclTotal) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="turnoverRentalFeeInclTotal">
        <th mat-header-cell *matHeaderCellDef> Rental Fee </th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.turnoverRentalFeeInclTotal) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="rollupNetBalancePayableRounded">
        <th mat-header-cell *matHeaderCellDef> Pay-out Amount </th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.rollupNetBalancePayableRounded) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="processStatus">
        <th mat-header-cell *matHeaderCellDef> Pay-out Status </th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.processStatus) }}
        </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSourceLength"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>


</div>
