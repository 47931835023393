import {DateRange} from "@angular/material/datepicker";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {CustomDateRangeModel, TimeFrames} from "../../shared/components/custom-date-filter/custom-date-range.model";

export interface PerformersResponse {
  bySalesList: any[];
  byTradingDensityList: any[];
}

export interface RetailerPerformance {
  retailerId: number,
  retailerCompanyName: string,
  saleAmount: number,
  industryCategoryCode: string,
  contributingPercentage: number,
  tradingDensity: number
}

export interface RetailerProductPerformance {
  productUuid: string,
  productName: string,
  productSku: string
  retailerId: number,
  saleAmount: number,
  quantitySold: number,
  avgQuantitySold: number,
  contributingPercentage: number,
  currentInventoryLevel: number,
  weeksToLast: number
}

export class MonthlyRetailerStats {
  bestMonthlyStats: MonthlyStat[] = [];
  tradingOutsideOfLeasePeriod: boolean = false;
  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export interface DashboardType {
  name: string;
  src: string;
  features: string[];
}

export class MonthlyStat {
  statName: string = '';
  statCode: string = '';
  statValue: number = 0;
  statFormattedValue: string = '';
  statType: StatisticType = StatisticType.DECIMAL;
  statMonth: string | null = null;
  statDescription: string = '';

  constructor(statName: string, statCode: string, statDescription: string, statType?: StatisticType) {
    this.statName = statName;
    this.statCode = statCode;
    this.statDescription = statDescription;
    if (statType) this.statType = statType;
  }
}

export enum StatisticType {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  DECIMAL = 'DECIMAL',
  CURRENCY_PER_SQM = 'CURRENCY_PER_SQM',
  INTEGER = 'INTEGER',
  DAYS = 'DAYS',
  COMPARE = 'COMPARE'
}

export interface DashboardChart<T extends ChartType> {
  chartData: ChartData<T>,
  chartName?: string,
  chartDescription: string,
  chartType: T,
  config: ChartOptions<T>,
  stats: { [key: string]: MonthlyStat },
  dateRange?: DateRange<Date>,
  loading: boolean
}

export const customDateRangeTablePreset = [
  new CustomDateRangeModel('this_month', 0, TimeFrames.MONTH, 'This month'),
  new CustomDateRangeModel('past_month', 1, TimeFrames.MONTH, 'Past month'),
  new CustomDateRangeModel('past_3_months', 3, TimeFrames.MONTH, 'Past 3 months'),
  new CustomDateRangeModel('past_6_months', 6, TimeFrames.MONTH, 'Past 6 months'),
  new CustomDateRangeModel('past_year', 1, TimeFrames.YEAR, 'Past year'),
]

export const dashboardTypes: DashboardType[] = [
  {
    name: 'Leads Report',
    src: './assets/images/dashboard-overview.png',
    features: [
      'dashboards_leads_view_best_month',
      'dashboards_leads_view_qualified_leads',
      'dashboards_leads_view_approval_rates',
      'dashboards_leads_view_categories'
    ]
  },
  {
    name: 'Leasing Report',
    src: './assets/images/dashboard-sales.png',
    features: [
      'dashboards_leasing_view_best_month',
      'dashboards_leasing_view_rental_income',
      'dashboards_leasing_view_rental_income_occupancy',
      'dashboards_leasing_view_trading_density_occupancy',
      'dashboards_leasing_view_rental_income_trading_density',
      'dashboards_leasing_view_effective_income_rent_ratio',
      'dashboards_leasing_view_rental_category_stats'
    ]
  },
  {
    name: 'Performance Report',
    src: './assets/images/dashboard-retailer.png',
    features: [
      'dashboards_sales_view_best_month',
      'dashboards_sales_view_overall_performance',
      'dashboards_sales_view_sales_comparison',
      'dashboards_sales_view_top_performers',
      'dashboards_sales_view_times_transactions',
      'dashboards_sales_view_category_performance',
      'dashboards_sales_view_footfall_statistics',
      'dashboards_sales_view_industry_performance_occupancy'
    ]
  },
  {
    name: 'Tenant Trading Report',
    src: './assets/images/dashboard-retailer.png',
    features: [
      'dashboards_retailer_view_basket_size',
      'dashboards_retailer_view_best_month',
      'dashboards_retailer_view_best_sellers',
      'dashboards_retailer_view_busiest_times',
      'dashboards_retailer_view_daily_transaction',
      'dashboards_retailer_view_inventory_forecast',
      'dashboards_retailer_view_overall_performance',
      'dashboards_retailer_view_poor_sellers',
      'dashboards_retailer_view_rent_performance',
      'dashboards_retailer_view_retailers'
    ]
  },
  {
    name: 'Footfall Report',
    src: './assets/images/dashboard-footfall.png',
    features: [
      'dashboards_footfall_view_footfall_rosebank' //todo - change this to footfall
    ]
  },
];
