import {ChartConfig, ChartDataConfig} from '../../dashboards/common/chart-config';
import {ChartOptionsService} from '../../dashboards/chart-options/chart-options.service';
import {DateUtilsService} from '../../../shared/services/dateUtils.service';
import {SalesReportComponent} from './sales-report.component';
import {MatTableDataSource} from '@angular/material/table';
import {RetailerProductPerformance} from '../../dashboards/dashboards.model';

export class SalesReportChartConfig extends ChartConfig<SalesReportComponent> {
  constructor(componentInstance: SalesReportComponent,
              private chartOptionsService: ChartOptionsService,
              private dateUtils: DateUtilsService) {
    super(componentInstance);
  }

  get bestSellersList(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.bestSellersDatasource = new MatTableDataSource<RetailerProductPerformance>(value);
        this.instance.bestSellersDatasource.paginator = this.instance.bestSellersPaginator;
        this.instance.bestSellersLoading = false;
      },
      error: err => {
        console.log(err);
        this.instance.bestSellersLoading = false;
      }
    };

  }

  get inventoryForecastList(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.inventoryForecastDatasource = new MatTableDataSource<RetailerProductPerformance>(value);
        this.instance.inventoryForecastDatasource.paginator = this.instance.forecastPaginator;
        this.instance.inventoryForecastLoading = false;
      },
      error: err => {
        console.log(err);
        this.instance.inventoryForecastLoading = false;
      }
    };

  }
}
