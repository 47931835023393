<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <div class="full-width" fxFlexOffset="10px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <span class="subtitle-default">Contact Information</span>
    <button (click)="toggleEdit()" *ngIf="!edit" [disabled]="loader.loading$ | async" class="button-action"
            mat-flat-button>
      <div class="button-text">Update</div>
    </button>
    <button (click)="addingContact = true" *ngIf="!edit" [disabled]="loader.loading$ | async" class="button-action"
            mat-flat-button>
      <div class="button-text">Add</div>
    </button>
    <button (click)="onSubmit()" *ngIf="edit" [disabled]="loader.loading$ | async" class="button-action"
            mat-flat-button>
      <div class="button-text">Submit</div>
    </button>
    <button (click)="toggleEdit()" *ngIf="edit" [disabled]="loader.loading$ | async" class="button-action-cancel"
            mat-flat-button>
      <div class="button-text">Cancel</div>
    </button>

    <div *ngIf="isRemovePanelOpen" fxFlexOffset="20px">
      <app-confirm-remove (confirmRemove)="removeContact($event)"
                          [message]="'Do you want to remove the selected contact.'"></app-confirm-remove>
    </div>
  </div>

  <div class="full-width" fxFlexOffset="30px" fxLayout="row" fxLayoutGap="20px">
    <div class="info-card" fxLayout="column" fxLayoutGap="20px">
      <span class="heading-default">Contact Numbers</span>
      <div *ngIf="addingContact === true" class="edit-table-row" fxFlexOffset="20px" fxLayout="row"
           fxLayoutAlign="space-between center" fxLayoutGap="15px">
        <div [formGroup]="addingContactForm" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div class="body-1-primary">NEW_CONTACT</div>
          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Country code</mat-label>
            <mat-select class="custom-select-field-value" formControlName="valueCode">
              <mat-option *ngFor="let country of countryCodes" [value]="country.code"
                          class="custom-select-field-option">{{country.country}} - {{country.code}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [ngClass]="edit ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                          floatLabel="always" fxFlexOffset="20px"
                          hideRequiredMarker="true">
            <mat-label>Contact number</mat-label>
            <input formControlName="value" matInput type="text">
          </mat-form-field>
        </div>

        <div class="edit-row-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <button [disabled]="loader.loading$ | async" class="button-primary" mat-flat-button>
            <mat-icon>check</mat-icon>
            <div (click)="saveIndividualContact()" class="button-text">Save</div>
          </button>

          <button (click)="addingContact = false" [disabled]="loader.loading$ | async" class="button-close-top"
                  mat-icon-button>
            <mat-icon>clear</mat-icon>
          </button>
        </div>

      </div>
      <div [formGroup]="contactsForm" class="full-width" fxLayout="column" fxLayoutGap="15px">
        <ng-container formArrayName="contacts">
          <ng-container *ngFor="let contact of contacts.controls; let index = index">
            <div [formGroupName]="index" class="full-width" fxLayout="row" fxLayoutAlign="start center"
                 fxLayoutGap="15px">
              <mat-form-field class="custom-select-field" [class.custom-text-autofill]="edit" appearance="outline"
                              floatLabel="always">
                <mat-label>Country code</mat-label>
                <mat-select class="custom-select-field-section" [class.custom-text-autofill]="edit" formControlName="valueCode">
                  <mat-option *ngFor="let country of countryCodes" [value]="country.code"
                              class="custom-select-field-option">{{country.country}} - {{country.code}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field [ngClass]="edit ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                              floatLabel="always" fxFlexOffset="20px"
                              hideRequiredMarker="true">
                <mat-label>Contact number</mat-label>
                <input [id]="'contact' + contact" formControlName="value" matInput type="text">
              </mat-form-field>

              <div *ngIf="edit">
                <button (click)="displayConfirmDelete(contact)" class="button-action" mat-flat-button>
                  <div class="button-text">delete</div>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

  </div>
</div>

