<div class="page-content page-padding" fxLayout="column">

  <div fxFlexOffset="10px">
    <div fxLayout="row">
      <span class="subtitle-default">Daily Transaction Summary</span>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px" *ngIf="dataSource">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <span class="heading-default">Daily Transaction Summary</span>

      <button
        [disabled]="!!(loader.loading$ | async)"
        [matMenuTriggerFor]="mainOptionsMenu"
        mat-icon-button
        matTooltip="Table options"
      >
        <mat-icon class="crud-table-main-toolbar-icon">more_vert</mat-icon>
      </button>
      <mat-menu #mainOptionsMenu="matMenu">
        <!-- Export to CSV -->
        <button (click)="exportToCsv()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to CSV</span>
        </button>

        <!-- Export to Excel -->
        <button (click)="exportToExcel()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to Excel</span>
        </button>

        <!-- Export to PDF -->
        <button (click)="exportToPdf()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to PDF</span>
        </button>

      </mat-menu>
    </div>
    <form fxFlexAlign="end">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="50px">
        <app-custom-date-filter [customDateRangePresets]="dateFilters"
                                [showBorder]="false"
                                (dateRange)="getDateRange($event)"
                                [initialPreset]="'2'"
                                [showClearButton]="true"></app-custom-date-filter>
      </div>
    </form>

    <div fxLayout="row" fxFlexOffset="25px">
      <table [dataSource]="dataSource" class="custom-table chart-table" mat-table>
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Daily Transaction Summary </th>
        </ng-container>

        <ng-container matColumnDef="transactionDate">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ dateUtils.displayDateWithTime(element.transactionDate) }}
          </td>
          <td *matFooterCellDef mat-footer-cell><span class="heading-default"><strong>Totals:</strong></span></td>

        </ng-container>

        <ng-container matColumnDef="saleAmountIncl">
          <th mat-header-cell *matHeaderCellDef> Total Sales </th>
          <td *matCellDef="let element" class="custom-table-cell"
              mat-cell> {{ utils.displayRandAmountWithDecimal(element.saleAmountIncl) }}
          </td>
          <td *matFooterCellDef
              mat-footer-cell><span
            class="heading-default"> {{ utils.displayRandAmountWithDecimal(getColumnToTal('saleAmountIncl')) }}
          </span></td>
        </ng-container>

        <ng-container matColumnDef="paymentsExternalIncl">
          <th *matHeaderCellDef mat-header-cell> External Payments</th>
          <td *matCellDef="let element" class="custom-table-cell btn-cursor"
              mat-cell>{{ utils.displayRandAmountWithDecimal(element.paymentsExternalIncl) }}
          </td>
          <td *matFooterCellDef
              mat-footer-cell><span
            class="heading-default">{{ utils.displayRandAmountWithDecimal(getColumnToTal('paymentsExternalIncl')) }}</span>
          </td>

        </ng-container>

        <ng-container matColumnDef="paymentsCollectedIncl">
          <th *matHeaderCellDef mat-header-cell> Internal Payments</th>
          <td *matCellDef="let element" class="custom-table-cell btn-cursor"
              mat-cell>{{ utils.displayRandAmountWithDecimal(element.paymentsCollectedIncl) }}
          </td>
          <td *matFooterCellDef
              mat-footer-cell><span
            class="heading-default">{{ utils.displayRandAmountWithDecimal(getColumnToTal('paymentsCollectedIncl')) }}</span>
          </td>

        </ng-container>

        <ng-container matColumnDef="transactionFeeIncl">
          <th mat-header-cell *matHeaderCellDef> Transaction Fee </th>
          <td *matCellDef="let element" class="custom-table-cell btn-cursor"
              mat-cell> {{ utils.displayRandAmountWithDecimal(element.transactionFeeIncl) }}
          </td>
          <td *matFooterCellDef
              mat-footer-cell><span
            class="heading-default"> {{ utils.displayRandAmountWithDecimal(getColumnToTal('transactionFeeIncl')) }}
          </span></td>
        </ng-container>

        <ng-container matColumnDef="turnoverRentalFeeIncl">
          <th mat-header-cell *matHeaderCellDef> Rental Fee </th>
          <td *matCellDef="let element" class="custom-table-cell btn-cursor"
              mat-cell> {{ utils.displayRandAmountWithDecimal(element.turnoverRentalFeeIncl) }}
          </td>
          <td *matFooterCellDef
              mat-footer-cell><span
            class="heading-default"> {{ utils.displayRandAmountWithDecimal(getColumnToTal('turnoverRentalFeeIncl')) }}
          </span></td>
        </ng-container>


        <ng-container matColumnDef="netBalancePayableRetailerInclRounded">
          <th *matHeaderCellDef mat-header-cell> Balance Payable</th>
          <td *matCellDef="let element" class="custom-table-cell btn-cursor"
              mat-cell> {{ utils.displayRandAmountWithDecimal(element.netBalancePayableRetailerInclRounded) }}
          </td>
          <td *matFooterCellDef
              mat-footer-cell><span
            class="heading-default"> {{ utils.displayRandAmountWithDecimal(getColumnToTal('netBalancePayableRetailerInclRounded')) }}
          </span></td>
        </ng-container>

        <ng-container matColumnDef="chart">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="custom-table-cell btn-cursor" (click)="checkTableRows()"
              *matCellDef="let element; let i = index" [attr.rowspan]="10" [ngClass]="i === 0 ? 'table-chart-show' : 'table-chart-hide'">
            <div class="subtitle-default mt-3">Daily Transaction Summary</div>
            <div class="full-width">
              <canvas baseChart
                      [height]="370"
                      [data]="chartData"
                      [plugins]="chartPlugins"
                      [options]="chartOptions"
                      [type]="'line'"></canvas>
            </div>
          </td>
          <td *matFooterCellDef mat-footer-cell></td>

        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr *matFooterRowDef="displayedColumns" mat-footer-row></tr>

      </table>

    </div>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSourceLength"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
  <div class="info-card-dark" fxFlexOffset="30px" fxLayout="column" fxLayoutGap="20px">
    <span class="heading-bold">Notes</span>
    <span class="body-1-regular-2">Totals are for all the data returned not just the current page</span>
  </div>

</div>
