<div class="px-3 py-2 pb-3 full-screen-height" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <div class="full-width pre-auth-header-div" fxLayout="column" fxLayoutGap="20px"  fxLayoutAlign="center center">
    <h1 class="title-large">Sign Up</h1>
    <h2 class="subtitle-regular">Already Have an account? <span (click)="gotoLogin()" class="heading-primary-1 btn-cursor">Log in</span>
    </h2>
  </div>
  <div class="full-width flex-row" fxLayoutAlign="center center">
    <form
      (ngSubmit)="submit()"
      [formGroup]="registrationForm"
      class="pre-auth-form"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Name</mat-label>
        <input formControlName="firstname" matInput/>
        <mat-error class="link-error" *ngIf="registrationForm.get('firstname')!.hasError('required')">Name is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Surname</mat-label>
        <input formControlName="lastname" matInput/>
        <mat-error class="link-error" *ngIf="registrationForm.get('lastname')!.hasError('required')">Surname is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Email address</mat-label>
        <input
          formControlName="email"
          matInput
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$"
          type="email"/>

        <mat-error class="link-error" *ngIf="registrationForm.get('email')!.hasError('required')">Email is required</mat-error>
        <mat-error class="link-error" *ngIf="registrationForm.get('email')!.hasError('pattern')">Email is not valid</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Create password</mat-label>
        <input
          #password
          [type]="hide ? 'password' : 'text'"
          formControlName="secret"
          matInput
        />
        <mat-icon (click)="hide = !hide" *ngIf="hide" class="btn-cursor icon-password-suffix" matSuffix>visibility</mat-icon>
        <mat-icon (click)="hide = !hide" *ngIf="!hide" class="btn-cursor icon-password-suffix" matSuffix>visibility_off</mat-icon>
        <mat-error class="link-error" *ngIf="registrationForm.get('secret')!.hasError('required')">Password is required</mat-error>

        <mat-error class="link-error" *ngIf="registrationForm.get('secret')!.hasError('not-valid')">
          Passwords need to be at least 8 characters long, contain at lest one upper and lower case letter, a number and
          one special character (!&#64;#$%)
        </mat-error>

      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Confirm password</mat-label>
        <input
          [type]="hide ? 'password' : 'text'"
          formControlName="confirmPassword"
          matInput/>
        <mat-icon (click)="hide = !hide" *ngIf="hide" class="btn-cursor icon-password-suffix" matIconSuffix>visibility</mat-icon>
        <mat-icon (click)="hide = !hide" *ngIf="!hide" class="btn-cursor icon-password-suffix" matIconSuffix>visibility_off</mat-icon>
        <mat-error class="link-error" *ngIf="registrationForm.get('confirmPassword')!.hasError('required')">Confirm password is required</mat-error>
        <mat-error class="link-error" *ngIf="registrationForm.get('confirmPassword')!.hasError('no-match')">Passwords do not match</mat-error>
      </mat-form-field>
      <mat-error class="link-error" *ngIf="errMessage">{{ errMessage }}</mat-error>

      <button
        [disabled]="canSubmit() || (loader.loading$ | async)"
        class="button-primary"
        mat-flat-button
        type="submit">
        <div class="button-text" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          {{ loading ? "Working..." : "Submit" }}
          <mat-icon *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </div>
      </button>

    </form>
  </div>
  <div class=" p3 full-width flex-row" fxLayoutAlign="center center">
    <app-footer-links [footerText]="SIGNUP"></app-footer-links>
  </div>
</div>
