import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, fromEvent, map, Observable, startWith} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {

  screenWidth = new BehaviorSubject<number>(window.innerWidth);
  public mobileView$: Observable<boolean> = this.screenWidth.asObservable().pipe(
    map(width => width < 600)
  );

  constructor(private ngZone: NgZone) {
    this.initializeResizeListener();
  }


  private initializeResizeListener(): void {
    fromEvent(window, 'resize')
      .pipe(
        startWith(null),
        map(() => window.innerWidth)
      )
      .subscribe(width => {
        this.ngZone.run(() => this.screenWidth.next(width));
      });
  }
}
