import {Component, OnInit} from '@angular/core';
import {ScreenSizeService} from "../../../shared/services/screen-size.service";
import {HelpCenterService} from "./help-center.service";
import {Category, Question} from "./questions.model";

@Component({
  selector: 'app-pos-help-center',
  templateUrl: './pos-help-center.component.html',
  styleUrls: ['./pos-help-center.component.scss']
})
export class PosHelpCenterComponent implements OnInit {

  allCategories: Category[] = [];

  constructor(private helpCenterService: HelpCenterService,
              public screenSize: ScreenSizeService,) {
  }

  ngOnInit() {
    this.loadCategories();
  }

  loadCategories() {
    this.helpCenterService.getAllCategories().subscribe({
      next: value => {
        this.allCategories = value.filter(f => f.questions.length > 0);

        this.allCategories.sort((a, b) => a.displayOrder - b.displayOrder);
        this.allCategories.forEach(cat => {
          cat.questions.sort((a, b) => a.displayOrder - b.displayOrder);
        })

        this.allCategories.forEach(ea => ea.showAll = false);
        const extra = this.allCategories.length % 3;
        for (let i = 0; i < (extra - 1); i++) {
          this.allCategories.push(Object.create(null));
        }
      },
      error: err => console.log(err)
    })
  }

  questionList(cat: Category): Question[] {
    if (cat.questions) {
      if (cat.showAll) {
        return cat.questions;
      } else {
        return [...cat.questions].slice(0, 3);
      }
    }
    return [];
  }

  openLink(link: string) {
    if (link.includes('https://')) {
      window.open(link, '_blank');
      return;
    }
    if (link.includes('.pdf')) {
      this.helpCenterService.getLocalDoc(link).subscribe(blob => {
        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl, '_blank');
      })
    }
  }

}
