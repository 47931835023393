import {Component, EventEmitter, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {lastValueFrom} from "rxjs";
import {Industry, IndustryCategory, Retailer} from "../../../../core/model/retailer.model";
import {GoogleAnalyticsService} from "../../../../core/services/google-analytics.service";
import {LoadingService} from "../../../../core/services/loading.service";
import {RetailerService} from "../../../../core/services/retailer.service";
import {CustomValidators} from "../../../../shared/validators/custom-validators";

@Component({
  selector: 'app-application-signup-retailer',
  templateUrl: './application-signup-retailer.component.html',
  styleUrls: ['./application-signup-retailer.component.scss']
})
export class ApplicationSignupRetailerComponent {
  @Output() login = new EventEmitter<void>();
  @Output() registerRetailerSuccess = new EventEmitter<Retailer>();
  @Output() submitError = new EventEmitter<string>();

  retailerReigstrationForm: FormGroup;
  website = false;
  public industryList: IndustryCategory[] = [];
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  public errorMsg: string | null = null;
  selectedIndustries: string[] = [];

  constructor(
    private fb: FormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    public loader: LoadingService,
    private retailerService: RetailerService
  ) {
    this.retailerReigstrationForm = this.resetRetailerForm();
    this.addProspectiveStore();
  }

  ngOnInit(): void {
    this.loadIndustries();
    this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'view', 'retailerRegister_view');
  }

  public canSubmitRetailer(): boolean {
    return !this.retailerReigstrationForm.invalid;
  }

  get prospectiveStores(): FormArray {
    return this.retailerReigstrationForm.get('prospectiveStores') as FormArray;
  }

  public addProspectiveStore(): void {
    this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'click', 'retailerRegister_additionalProspectiveLocation_add');
    this.prospectiveStores.push(this.buildProspectiveStoreForm());
  }

  private buildProspectiveStoreForm(): FormGroup {
    return this.fb.group({
      address: [null],
      geometry: [null],
      place: [null]
    });
  }

  private resetRetailerForm(): FormGroup {
    return this.fb.group({
      companyName: [null, Validators.required],
      industries: [[], Validators.required],
      websiteUrl: [null, Validators.compose([CustomValidators.websiteUrl])],
      mobile: [null, Validators.required],
      socialMediaUrl: [null, Validators.compose([CustomValidators.websiteUrl])],
      prospectiveStores: this.fb.array([])
    });
  }


  private loadIndustries(): void {
    lastValueFrom(this.retailerService.getPublicIndustries())
      .then((res: IndustryCategory[]) => {
        this.industryList = res;
        this.industryList.forEach(cat => {
          cat.selected = false;
          cat.industries.forEach(ind => {
            ind.selected = false
          });
        });
      },
        (err: string) => {
          this.errorMsg = 'Could not load industries due to an unexpected error';
          console.log(err);
        }
      );
  }

  groupClicked(group: IndustryCategory): void {
    this.industryList.forEach(gro => {
      if (gro.id === group.id) {
        gro.industries.forEach(ind => ind.selected = !gro.selected);
        gro.selected = !gro.selected;
      }
    });
    this.updateSelected();
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.industryList.forEach(gro => {
      gro.industries.forEach(ind => {
        if (ind.selected) newSelects.push(ind.code);
      });
    }
    );
    this.selectedIndustries = newSelects;
  }

  optionClicked(group: IndustryCategory, item: Industry): void {
    if (group.selected) group.selected = !group.selected;
    item.selected = !item.selected;
    this.updateSelected();
  }

  gotoLogin(): void {
    this.login.emit();
  }

    /**
   * Check if the form control is invalid or a specific error is present
   * @param formControlName the name of the form control
   * @param errorName the name of the error, else invalid is checked
   * @returns error state
   */
    public hasError(formControlName: string, errorName?: string): boolean {
      const control = this.retailerReigstrationForm.get(formControlName);
      return control!.touched && (errorName ? control!.hasError(errorName) : control!.invalid);
    }
}
