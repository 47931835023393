import {CommonModule, NgStyle} from '@angular/common';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {CurrentContextService} from "../../../core/services/security/current-context.service";

@Component({
  selector: 'app-application-wrapper',
  styleUrls: ['application-wrapper.component.scss'],
  standalone: true,
  imports: [CommonModule, NgStyle],
  templateUrl: './application-wrapper.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ApplicationWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('nterFormDiv') nterFormDiv!: ElementRef<HTMLElement>;

  locationCode!: string;
  offeringCode!: string;
  embedKey!: string;

  private resizeObserver: ResizeObserver | undefined;
  private lastHeight = 0;

  constructor(private route: ActivatedRoute,
              private contextService: CurrentContextService) {
    const offering = this.contextService.getCurrentOffering();

    if (offering && offering.applicationFormQrCodeDestination) {
      window.location.replace(offering.applicationFormQrCodeDestination);
    }
  }

  ngOnInit() {
    this.locationCode = this.route.snapshot.paramMap.get('locationCode')!;
    this.offeringCode = this.route.snapshot.paramMap.get('offeringCode')!;
    this.embedKey = this.route.snapshot.queryParamMap.get('embedKey')!;
  }

  ngAfterViewInit() {
    const nterFormDiv = this.nterFormDiv.nativeElement;

    if (nterFormDiv) {
      // Create a script element
      this.loadCustomElement();
      this.sendHeight();
    }
  }

  sendHeight(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const newHeight = entry.contentRect.height;

        // Check if the new height is different enough to notify the parent
        if (Math.abs(newHeight - this.lastHeight) > 10) {  // Adjust threshold as needed
          this.lastHeight = newHeight;
          // Send the new height to the parent window
          window.parent.postMessage({ height: newHeight }, environment.apiPortal);
        }
      }
    });

    // Observe the whole document body or a specific wrapper element
    this.resizeObserver.observe(this.nterFormDiv?.nativeElement || document.body);
  }

  ngOnDestroy(): void {
    // Clean up script and custom element on destroy
    const container = this.nterFormDiv.nativeElement;
    container.innerHTML = '';
  }

  public loadCustomElement() {
    const container = this.nterFormDiv.nativeElement;

    if (container) {
      // Clear the container
      container.innerHTML = '';

      const scriptElement = document.createElement('script');
      scriptElement.src = environment.apiAppForm;

      // Create the custom element
      const customElement = document.createElement('nter-app-form');
      customElement.setAttribute('location-code', this.locationCode);
      customElement.setAttribute('offering-code', this.offeringCode);
      customElement.setAttribute('embed-key', this.embedKey);

      container.appendChild(scriptElement);
      container.appendChild(customElement);
    }
  }
}
