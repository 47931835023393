import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup} from "@angular/forms";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {POSSaleStatus, SaleStatus, TransactionStatus, TransactionSummary} from "../../accounts.model";
import {DateRange} from "@angular/material/datepicker";
import {AccountsService} from "../../accounts.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {EMPTY, expand, forkJoin, map, Observable, of, takeWhile, tap} from "rxjs";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import * as Papa from 'papaparse';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {LoadingService} from '../../../../core/services/loading.service';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-transaction-schedule',
  templateUrl: './transaction-schedule.component.html',
  styleUrls: ['./transaction-schedule.component.scss']
})
export class TransactionScheduleComponent implements OnInit, AfterViewInit  {
  @ViewChild('paginator') paginator!: MatPaginator;
  filterForm: FormGroup;

  dataSource = new GenericDatasource<TransactionSummary>(this.accountsService);
  displayedColumns = ['transactionInvoiceNumber', 'transactionDate', 'productName', 'paymentMethods', 'quantity', 'saleAmountIncl', 'transactionFeeIncl', 'turnoverRentalFeeIncl', 'netBalancePayableRetailerInclRounded', 'posStatus', 'processingStatus'];

  exportModel = [
    {key: 'transactionInvoiceNumber', displayName: 'Transaction Invoice Number'},
    {key: 'transactionDate', displayName: 'Transaction Date'},
    {key: 'saleAmountIncl', displayName: 'Sale Amount (Tax Incl)'},
    {key: 'productName', displayName: 'Product Name'},
    {key: 'paymentMethods', displayName: 'Payment Methods'},
    {key: 'quantity', displayName: 'Quantity'},
    {key: 'transactionFeeIncl', displayName: 'Transaction Fee (Tax Incl)'},
    {key: 'turnoverRentalFeeIncl', displayName: 'Turnover Rental Fee (Tax Incl)'},
    {key: 'netBalancePayableRetailerInclRounded', displayName: 'Balance Payable '},
    {key: 'pointOfSaleStatus', displayName: 'Point of Sale Status'},
    {key: 'saleStatus', displayName: 'Sale Status'},
    {key: 'processingStatus', displayName: 'Processing Status'},
  ];

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 7, TimeFrames.DAYS, 'Last 7 days'),
    new CustomDateRangeModel('2', 1, TimeFrames.MONTH, 'This month')
  ]


  posStatuses: string[] = Object.keys(POSSaleStatus);
  saleStatuses: string[] = Object.keys(SaleStatus);
  transactionStatuses!: any[];

  tableTitle = 'Transactions Schedule';


  allData: any[] = [];
  pageSize = '500'; // Set your page size
  currentPage = 0;
  hasFilterChanged: boolean = false;

  constructor(private accountsService: AccountsService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private currentContext: CurrentContextService,
              public loader: LoadingService) {
    this.filterForm = new FormGroup({
      posStatus: new FormControl([]),
      processingStatus: new FormControl([]),
    })
  }

  ngOnInit() {
    this.setTransactionStatuses();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadTransactionsSchedule()))
      .subscribe();
  }

  filterData(): void {
    this.loadTransactionsSchedule();
  }

  loadTransactionsSchedule() {
    const filters = [
      {name: 'saleStatus', val: this.filterForm.get('posStatus')?.value},
      {name: 'processingStatus', val: this.filterForm.get('processingStatus')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ];

    let page;
    if (this.paginator){
      page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'transactionDate,desc'}
    } else {
      page = {size: '10', page: '0', sort: 'transactionDate,desc'}
    }
    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/accounts/transactions/schedule`, page, filters);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    if (event.dateRange.start && event.dateRange.end) {
      this.dateRange = event.dateRange;
      this.hasFilterChanged = true;
      this.loadTransactionsSchedule();
    }
  }

  setTransactionStatuses() {
    const pending = [TransactionStatus.NEW, TransactionStatus.PENDING_PAYMENT, TransactionStatus.STAGED_IN_PAYMENT_BATCH, TransactionStatus.SUBMITTED_FOR_PAYMENT, TransactionStatus.PENDING_REFUND_RECOVERY, TransactionStatus.STAGED_FOR_RECOVERY, TransactionStatus.SUBMITTED_FOR_RECOVERY]
      .map(m => m.toString());
    const error = Object.keys(TransactionStatus).filter(f => f.includes('ERROR')).map(m => m.toString());
    this.transactionStatuses = Object.keys(TransactionStatus).map(m => {
      if (pending.includes(m) || error.includes(m)) {
        return;
      }
      return { label: this.utils.displayStatus(m), val: [m]};
    }).filter(f => f);
    this.transactionStatuses.push({label: 'Pending payment', val: pending});
    this.transactionStatuses.push({label: 'Error', val: error});
  }


  getAllData(): Observable<any[]> {

    if (!this.hasFilterChanged && this.allData.length > 0) {
      return of(this.allData);
    }

    const filters = this.getCurrentFilters();

    const requests: Observable<any[]>[] = [];
    this.allData = []; // Reset the array before fetching
    this.currentPage = 0;

    const fetchData = (): Observable<any> => {

      return this.accountsService.getAll(`/${this.currentContext.currentRetailer.id}/accounts/transactions/schedule`, {
        size: this.pageSize,
        page: `${this.currentPage}`,
        sort: 'transactionDate,desc'
      }, filters)
        .pipe(
          expand(response => {
            if (response.content && response.content.length > 0) {
              this.allData = [...this.allData, ...response.content];
              this.currentPage++;

              return this.accountsService.getAll(`/${this.currentContext.currentRetailer.id}/accounts/transactions/schedule`, {
                size: this.pageSize,
                page: `${this.currentPage}`,
                sort: 'transactionDate,desc'
              }, filters)
                .pipe(
                  catchError(error => {
                    console.log(`error fetching page: ${this.currentPage}: `, error)
                    return EMPTY;
                  })
                )

            } else {
              // No more data, process or display allData as needed
              return EMPTY;
            }
          }),
          takeWhile((response) => response.content.length > 0),
          catchError((error) => {
            console.error("Error fetching data", error)
            return of([]);
          })
        )
    };
    requests.push(fetchData());

    this.hasFilterChanged = false;
    return forkJoin(requests).pipe(
      map(() => this.allData)
    );
  }

  exportToCsv() {
    // load all the data for all the pages then export
    this.getAllData()
      .subscribe(allData => {
        const dataWithDisplayLabels = allData.map(
          (item: any) => {
            const newItem: any = {};
            this.exportModel.forEach((column) => {
              newItem[column.displayName.toUpperCase()] = item[column.key];
            });
            return newItem;
          }
        );

        const csv = Papa.unparse(dataWithDisplayLabels);
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);

        this.downloadFile(url, `${this.tableTitle}-${new Date().toISOString()}.csv`);
      });

  }

  exportToExcel() {
    this.getAllData()
      .subscribe(allData => {
        const dataWithDisplayLabels = allData.map(
          (item: any) => {
            const newItem: any = {};
            this.exportModel.forEach((column) => {
              newItem[column.displayName.toUpperCase()] = item[column.key];
            });
            return newItem;
          }
        );

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataWithDisplayLabels);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');
        XLSX.writeFile(wb, `${this.tableTitle}-${new Date().toISOString()}.xlsx`);
      });

  }

  exportToPdf() {
    this.getAllData()
      .subscribe(allData => {
        const doc = new jsPDF('landscape', 'pt', 'a4');
        const columns = this.exportModel.map((col) => col.displayName);
        const body = allData.map((row: any) =>
          this.exportModel.map((col) => row[col.key]?.toString() || '')
        );
        autoTable(doc, {
          head: [columns],
          body: body,
        });
        doc.save(`${this.tableTitle}-${new Date().toISOString()}.pdf`);
      });
  }


  private downloadFile(url: string, fileName: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private getCurrentFilters() {
    return [
      {name: 'saleStatus', val: this.filterForm.get('posStatus')?.value},
      {name: 'processingStatus', val: this.filterForm.get('processingStatus')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ];
  }
}
