<div class="page-padding page-content" fxLayout="column">
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="15px"
       fxLayoutAlign="center start" fxLayoutAlign.gt-xs="space-between center">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <span class="subtitle-default">Banking Details</span>
      <button (click)="toggleIsAddingPaymentMethod()" *ngIf="!addingPaymentMethod" class="button-action" mat-flat-button [disabled]="editing == 'billing' || !!(loader.loading$ | async)"><div class="button-text">Update</div></button>
      <button (click)="onSaveChanges()" *ngIf="addingPaymentMethod" class="button-action" mat-flat-button [disabled]="editing == 'billing' || !!(loader.loading$ | async)"><div class="button-text">Submit</div></button>
      <button (click)="toggleIsAddingPaymentMethod()" *ngIf="addingPaymentMethod" class="button-action-cancel" mat-flat-button [disabled]="editing == 'billing' || !!(loader.loading$ | async)"><div class="button-text">Cancel</div></button>
    </div>
    <app-info-panel [message]="'You can update your banking information at any time.'"
                    [type]="'info'"></app-info-panel>
  </div>
  <div class="full-width" fxLayout="column" fxLayoutGap="20px" fxFlexOffset="30px">
    <div *ngIf="addingPaymentMethod" class="info-card" style="padding: 30px 0;" fxLayout="row">
      <form [formGroup]="addPaymentMethodForm" class="full-width" fxLayout="column" fxLayoutAlign="start start"
            fxLayoutGap="30px">

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="addingPaymentMethod ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker>
            <mat-label>Account holder name</mat-label>
            <input #accountName formControlName="accountName" matInput type="text">
            <mat-error *ngIf="addPaymentMethodForm.get('accountName')!.hasError('required')">
              Name is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="addingPaymentMethod ? 'custom-select-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker>
            <mat-label>Bank Account Type</mat-label>
            <mat-select class="custom-select-field-section" formControlName="bankAccountType" required>
              <mat-option class="custom-select-field-option">--</mat-option>
              <mat-option *ngFor="let type of bankAccountTypes" [value]="type.code" class="custom-select-field-option">
                {{type.type}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="addPaymentMethodForm.get('bankAccountType')!.hasError('required')">
              Bank account type is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div *ngIf="paymentMethodsOpts" class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="addingPaymentMethod ? 'custom-select-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker>
            <mat-label>Bank Name</mat-label>
            <mat-select class="custom-select-field-section" formControlName="bankName" required>
              <mat-option class="custom-select-field-option">--</mat-option>
              <mat-option *ngFor="let bank of bankOptions" [value]="bank.centralBranchCode"
                          class="custom-select-field-option">
                {{bank.bankName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="addPaymentMethodForm.get('bankName')!.hasError('required')">
              A Bank name is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="addingPaymentMethod ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker>
            <mat-label>Account number</mat-label>
            <input #bankAccountNumber formControlName="bankAccountNumber" matInput type="text">
            <mat-error *ngIf="addPaymentMethodForm.get('bankAccountNumber')!.hasError('required')">
              Account number is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div *ngIf="paymentMethodsOpts" class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field appearance="outline" class="custom-select-field" floatLabel="always">
            <mat-label>Payment type</mat-label>
            <mat-select class="custom-select-field-section" formControlName="methodType" [(value)]="selectedPaymentMethod" required>
              <mat-option *ngFor="let type of paymentMethodsOpts" [value]="type.typeCode"
                          class="custom-select-field-option">
                {{type.typeName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="addPaymentMethodForm.get('methodType')!.hasError('required')">
              A Payment type is required
            </mat-error>
          </mat-form-field>
        </div>

      </form>

    </div>
    <div class="info-card" fxLayout="column">
      <div *ngFor="let method of paymentMethodsArray; let i=index" class="links-container">
        <div class="link-container" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="body-1-regular-2" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="2px">
              <div class="body-1-default">{{paymentsService.getTypeNameFromCode(method.paymentMethodTypeCode)}}
              </div>
              <div *ngIf="paymentMethodsArray && updateUsers.length > 0">
                <span>Submitted by {{getUsernameForId(method.updateUserId)}}
                  ({{(method.updateUserId)}}) at {{dateUtils.displayShortDate(method.updateDate)}}</span>
              </div>
            </div>
          </div>

          <div class="edit-container edit" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <button (click)="removeMethod(method)" class="edit-row-button button-action" mat-flat-button [disabled]="editing == 'billing' || !!(loader.loading$ | async)">
              <div class="button-text">Remove</div>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="paymentMethodsArray.length == 0">
        <div class="body-1-regular-2" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="2px">
          <span>You do not have any linked payment methods. Add one by clicking the "Update" button above.</span>
        </div>
      </div>

    </div>
  </div>
</div>
