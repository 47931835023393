import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {DailyTransactionsSummary} from "../../accounts.model";
import {AccountsService} from "../../accounts.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {ChartConfiguration, ChartData} from "chart.js";
import {DateRange} from "@angular/material/datepicker";
import {barChartOptions, barChartPlugins} from "./chart-config.constant";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import {chartColors} from "../../../dashboards/chart-options/chart-colors.model";
import {MatTableDataSource} from '@angular/material/table';
import {LoadingService} from '../../../../core/services/loading.service';
import * as Papa from 'papaparse';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.scss']
})
export class TransactionSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  dataSource = new MatTableDataSource<DailyTransactionsSummary>();
  displayedColumns = ['transactionDate', 'saleAmountIncl','paymentsExternalIncl', 'paymentsCollectedIncl', 'transactionFeeIncl', 'turnoverRentalFeeIncl',  'netBalancePayableRetailerInclRounded', 'chart'];

  exportModel = [
    {key: 'retailerCompanyName', displayName: 'Retailer'},
    {key: 'transactionDate', displayName: 'Transaction Date'},
    {key: 'saleAmountIncl', displayName: 'Sale Amount (Tax Incl)'},
    {key: 'transactionFeeIncl', displayName: 'Transaction Fee (Tax Incl)'},
    {key: 'turnoverRentalFeeIncl', displayName: 'Turnover Rental Fee (Tax Incl)'},
    {key: 'paymentsCollectedIncl', displayName: 'Internal Payments (Tax Incl)'},
    {key: 'paymentsExternalIncl', displayName: 'External Payments (Tax Incl)'},
    {key: 'netBalancePayableRetailerInclRounded', displayName: 'Balance Payable (Tax Incl)'},
  ]

  // @ts-ignore
  chartOptions: ChartConfiguration<'line'>['options'] = barChartOptions;
  chartPlugins = barChartPlugins;
  chartData: ChartData<'line'> = {
    labels: [],
    datasets: [
      {data: []}
    ]
  }

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 1, TimeFrames.DAYS, 'Today'),
    new CustomDateRangeModel('2', 7, TimeFrames.DAYS, 'Last 7 Days')
  ];

  tableTitle = 'Daily Transaction Summary';

  constructor(private accountsService: AccountsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              public utils: UtilsService,
              public loader: LoadingService) {
  }

  get dataSourceLength() {
    return this.dataSource.data.filter(f => f.leaseUuid).length;
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
  }

  ngOnInit() {
    this.dataSource.data = [];
  }

  loadChartData() {
    const filters = [
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]
    this.accountsService.getDailyTransactionChartData(filters).subscribe({
      next: value => {
        this.chartData = {
          labels: value.chartData.map(m => this.dateUtils.displayShortDate(m.dateLabel)),
          datasets: [{
            data: value.chartData.map(m => m.data),
            backgroundColor: chartColors.BLUE,
            borderColor: chartColors.BLUE,
            label: 'Sales',
            pointBackgroundColor: chartColors.BLUE,
            pointBorderColor: chartColors.BLUE,
            fill: true,
            spanGaps: true,
            pointRadius: 0
          }]
        }
      }
    })
  }

  loadTransactionsSummary() {
    const filters = [
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]
    this.accountsService.getAllForDateRange(filters)
      .subscribe({
        next: value => {
          this.dataSource.data = value;
          this.checkTableRows();
        },
        error: err => {
          console.log(err);
        }
      });
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    if (event.dateRange.start && event.dateRange.end) {
      this.dateRange = event.dateRange;
      this.loadTransactionsSummary();
      this.loadChartData();
    }
  }

  checkTableRows() {
    if (this.dataSource.data.length % 10 != 0) {
      const fullPages = Math.floor(this.dataSource.data.length / 10);
      const rowsToAdd = 10 - (this.dataSource.data.length - (fullPages * 10))
      for (let i = 0; i < rowsToAdd; i++) this.dataSource.data.push(Object.create(null));
    }
    this.dataSource.paginator = this.paginator;
  }

  getColumnToTal(propertyKey: string): number {
    let sum = this.dataSource.filteredData.map((item: DailyTransactionsSummary) => item[propertyKey as keyof DailyTransactionsSummary] as number)
      .filter(f => f !== undefined)
      .reduce((acc, value) => acc + value, 0);
    return sum;
  }

  exportToCsv() {
    const dataWithDisplayLabels = this.dataSource.data.map(
      (item: any) => {
        const newItem: any = {};
        this.exportModel.forEach((column) => {
          newItem[column.displayName.toUpperCase()] = item[column.key];
        });
        return newItem;
      }
    );

    const csv = Papa.unparse(dataWithDisplayLabels);
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);

    this.downloadFile(url, `${this.tableTitle}-${new Date().toISOString()}.csv`);

  }

  exportToExcel() {

    const dataWithDisplayLabels = this.dataSource.filteredData.map(
      (item: any) => {
        const newItem: any = {};
        this.exportModel.forEach((column) => {
          newItem[column.displayName.toUpperCase()] = item[column.key];
        });
        return newItem;
      }
    );

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataWithDisplayLabels);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${this.tableTitle}-${new Date().toISOString()}.xlsx`);
  }

  exportToPdf() {

    const doc = new jsPDF('landscape', 'pt', 'a4');
    const columns = this.exportModel.map((col) => col.displayName);
    const body = this.dataSource.filteredData.map((row: any) =>
      this.exportModel.map((col) => row[col.key]?.toString() || '')
    );
    autoTable(doc, {
      head: [columns],
      body: body,
    });
    doc.save(`${this.tableTitle}-${new Date().toISOString()}.pdf`);

  }

  private downloadFile(url: string, fileName: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


}
