<div style="background-color: white" fxLayout="column">

  <div class="full-width banner-header" fxLayout="column" fxLayoutAlign="end start" fxLayoutGap="20px">
    <div class="page-padding full-width" fxLayout="column" fxLayout.gt-xs="row"
         fxLayoutAlign="space-between center" fxFlexOffset="20px" fxLayoutGap="10px">
      <span class="title-default" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <span>Welcome {{retailerName}}!</span>
      </span>
      <span class="heading-regular" *ngIf="!bestStats || (bestStats && !bestStats.tradingOutsideOfLeasePeriod)">Explore the report to see highlights and other great insights!</span>
      <app-info-panel type="warn" message="You are currently trading outside of your lease period. Some data might be inaccurate."
                      *ngIf="bestStats && bestStats.tradingOutsideOfLeasePeriod"></app-info-panel>
    </div>
    <mat-divider class="full-width"></mat-divider>
    <span class="heading-default page-padding" style="padding-bottom: 20px"></span>
  </div>

  <div class="page-padding" fxLayout="column" fxLayoutGap="15px" fxLayoutGap.gt-xs="30px">

    <app-dashboards-best-stats [bestMonthlyStats]="bestStats.bestMonthlyStats"></app-dashboards-best-stats>

    <div fxFlexAlign="end">
      <span class="body-1-regular-1">Filter data for the below graphs by pre-set timelines (this year, this month, etc.) or choose any custom date range</span>
    </div>

    <app-dashboards-chart-section sectionName="Sales Performance"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="0"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="salesPerformanceChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider></mat-divider>

    <app-dashboards-chart-section sectionName="Rent Performance"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="1"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="rentPerformanceChart"
                                  [chart2]="avgBasketSizeChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider></mat-divider>

    <app-dashboards-chart-section sectionName="Times & Transactions"
                                  [customDateRangePresets]="customDateRangePresets1"
                                  [initialPreset]="'this_year'"
                                  [dateRangeIndex]="2"
                                  [dateFilterType]="'custom-range'"
                                  [chart1]="busiestSalesTimesChart"
                                  [chart2]="busiestSalesDaysChart"
                                  (dateRangeChanged)="getDateRange($event.event, $event.index)">
    </app-dashboards-chart-section>

    <mat-divider></mat-divider>
  </div>
</div>
